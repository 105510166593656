define("momentumhub/routes/create-module", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(param) {
      if (param.id) {
        var that = this;
        var hedgeDocPreviewUrl = _environment.default.APP.HEDGE_DOC + "/" + param.id;
        var hedgeDocUrl = hedgeDocPreviewUrl + '?both';
        this.getModule(param.id);
        // var module = this.store.query('module', {
        //     identifier: param.id
        // }).then(function (modules) {
        //     that.controllerFor("create-module").set('module', modules.get('firstObject'));
        // });
        this.controllerFor("create-module").set('hedgeDocUrl', hedgeDocUrl);
        this.controllerFor("create-module").set('hedgePreviewDocUrl', '');
        this.controllerFor("create-module").set('isEditable', false);
        this.controllerFor("create-module").set('module_id', param.id);
        this.controllerFor("create-module").set('pageSelected', 0);
        this.controllerFor("create-module").set('showPreview', false);
        this.controllerFor("create-module").set('previewpages', []);
      }
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    getModule: function getModule(identifier) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      $.ajax({
        url: _environment.default.APP.API_HOST + '/modules?identifier=' + identifier,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var modules = res.module;
          if (modules[0]) {
            that.controllerFor("create-module").set('module', modules[0]);
          }
        },
        error: function error(err, xH) {
          var errorMessage = err.responseText;
          console.log(errorMessage);
        }
      });
    }
  });
});