define("momentumhub/controllers/modules", ["exports", "momentumhub/config/environment", "momentumhub/utils"], function (_exports, _environment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    selectedModuleId: "",
    selectedModuleTags: [],
    modules: [],
    moduleModel: [],
    selectedModuleName: "",
    selectedmoduleDescription: "",
    selectedModuleThumbnail: "",
    hideSaveButtons: false,
    orderByOption: [{
      "id": 1,
      "name": "Search By Name or Id"
    }, {
      "id": 2,
      "name": "Search By Tags"
    }
    // { "id": 3, "name": "Search By Date Created" },
    // { "id": 4, "name": "Status" },
    ],
    delayTimer: "",
    deleteComponentName: "",
    deleteComponentTitle: "",
    deleteComponentId: "",
    isPreviousAvailable: "",
    isNextAvailable: "",
    pageNumber: 1,
    pagefilter: "",
    module_search: "",
    showModule: true,
    totalModules: "",
    totalViewableModules_start: "",
    totalViewableModules_end: "",
    studyStatus: false,
    rewards: null,
    TR_ClueCalmers_available: null,
    TR_ClueCalmers_required: null,
    TR_ClueCalmers_completed: null,
    TR_ClimbingPlans_available: null,
    TR_ClimbingPlans_required: null,
    TR_ClimbingPlans_completed: null,
    TR_PowerThinking_available: null,
    TR_PowerThinking_required: null,
    TR_PowerThinking_completed: null,
    TR_MoodBoost_available: null,
    TR_MoodBoost_required: null,
    TR_MoodBoost_completed: null,
    TR_ChangeFocus_available: null,
    TR_ChangeFocus_required: null,
    TR_ChangeFocus_completed: null,
    truecalmers_stars: null,
    climbingplans_stars: null,
    powerThinking_stars: null,
    moodboost_stars: null,
    changeFocus_stars: null,
    helpButtonModule: null,
    currentUser: null,
    setHelpButtonModule: function setHelpButtonModule() {
      var moduleId = null;
      var that = this;
      if ((0, _utils.getParticipantCategory)() === 'teen') {
        moduleId = _environment.default.APP.SESSIONS_HELP_SEEKING_TEEN;
      } else {
        moduleId = _environment.default.APP.SESSIONS_HELP_SEEKING_CHILD;
      }
      if (moduleId) {
        that.store.query('module', {
          identifier: moduleId
        }).then(function (result) {
          ;
          var module = result.firstObject;
          that.set('helpButtonModule', module);
        });
      }
    },
    resourcesAndModules: Ember.computed('model.completedModules', 'model.resourcesModules', function () {
      var resourcesAndModules = this.get('model.completedModules').toArray().concat(this.get('model.resourcesModules').toArray());
      return resourcesAndModules.sort(function (a, b) {
        return a.created_at > b.created_at ? 1 : -1;
      });
    }),
    getCurrentSession: function getCurrentSession() {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var userId = this.session.get('data.userId');
      var viewingId = this.session.get('data.viewingId');
      var currentUserType = this.session.get('data.user_type');
      if (viewingId) {
        userId = viewingId;
      }
      this.set('studyStatus', false);
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?is_onboarding=false&user=' + userId,
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var studyusers = res.studyuser;
          var length = studyusers.length;
          console.log('studyuser', studyusers);
          if (length > 0) {
            var studyuser = studyusers[0];
            if (studyuser.completed && !studyuser.required_module_id) {
              return;
            }
            that.set('isStudyAvailable', true);
            var lastStudyId = studyuser.id;
            that.set('lastStudyId', lastStudyId);
            var now = new Date();
            if (studyuser.next_node_execution_time) {
              var executionTime = new Date(studyuser.next_node_execution_time);
              if (executionTime > now) that.set('nextNodeExecutionTime', studyuser.next_node_execution_time);else that.set('nextNodeExecutionTime', null);
            }
            var current_phase = studyuser.current_phase;
            if (currentUserType == 'PT') {
              if (current_phase) {
                that.set('studyStatus', "Continue");
              } else {
                that.set('studyStatus', "Start Study");
              }
            }
          }
        },
        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    },
    fetchStars: function fetchStars() {
      var _this = this;
      var isParticipant = this.session.get('data.isParticipant');
      var currentUser = this.get('currentUser');
      if (isParticipant) {
        this.get('store').query('userrewardvar', {
          user: currentUser.id
        }).then(function (rewards) {
          console.log('response', rewards.firstObject.reward_vars);
          var rewards = rewards.firstObject.reward_vars;
          _this.set('rewards', rewards);
          _this.set('TR_ClueCalmers_available', rewards.TR_ClueCalmers_available == 1 ? true : false);
          _this.set('TR_ClimbingPlans_available', rewards.TR_ClimbingPlans_available == 1 ? true : false);
          _this.set('TR_PowerThinking_available', rewards.TR_PowerThinking_available == 1 ? true : false);
          _this.set('TR_MoodBoost_available', rewards.TR_MoodBoost_available == 1 ? true : false);
          _this.set('TR_ChangeFocus_available', rewards.TR_ChangeFocus_available == 1 ? true : false);
          var maxTrueCalmersRequired = rewards.TR_ClueCalmers_required <= 5 ? rewards.TR_ClueCalmers_required : 5;
          var maxClimbingPlansRequired = rewards.TR_ClimbingPlans_required <= 5 ? rewards.TR_ClimbingPlans_required : 5;
          var maxPowerThinkingRequired = rewards.TR_PowerThinking_required <= 5 ? rewards.TR_PowerThinking_required : 5;
          var maxMoodBoostRequired = rewards.TR_MoodBoost_required <= 5 ? rewards.TR_MoodBoost_required : 5;
          var maxChangeFocusRequired = rewards.TR_ChangeFocus_required <= 5 ? rewards.TR_ChangeFocus_required : 5;
          function createStarsArray(max, filled) {
            var arr = [];
            for (var i = 0; i < max; i++) {
              if (i < filled) {
                arr.push({
                  hasStar: true
                });
              } else {
                arr.push({
                  hasStar: false
                });
              }
            }
            return arr;
          }
          _this.set('truecalmers_stars', createStarsArray(maxTrueCalmersRequired, rewards.TR_ClueCalmers_completed));
          _this.set('climbingplans_stars', createStarsArray(maxClimbingPlansRequired, rewards.TR_ClimbingPlans_completed));
          _this.set('powerThinking_stars', createStarsArray(maxPowerThinkingRequired, rewards.TR_PowerThinking_completed));
          _this.set('moodboost_stars', createStarsArray(maxMoodBoostRequired, rewards.TR_MoodBoost_completed));
          _this.set('changeFocus_stars', createStarsArray(maxChangeFocusRequired, rewards.TR_ChangeFocus_completed));
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentUser', (0, _utils.getCurrentUser)(this.session));
      this.getCurrentSession();
    },
    actions: {
      routeToStudyView: function routeToStudyView() {
        var lastStudyId = this.lastStudyId;
        var url = window.location.origin + '/view/' + lastStudyId + '/na';
        window.location.href = url;
        // this.get('router').transitionTo('studyview', lastStudyId, "na");
      },
      getCurrentStudy: function getCurrentStudy() {
        this.getCurrentSession();
      },
      showOptionalModule: function showOptionalModule(option) {
        this.set('showModule', option);
      },
      fetchStarsArray: function fetchStarsArray() {
        this.fetchStars();
      },
      createModule: function createModule() {
        $('.hover_bkgr_fricc-study-module').show();
      },
      editModule: function editModule(moduleId) {
        this.transitionToRoute('create-module', moduleId);
      },
      openMode: function openMode(moduleId) {
        this.transitionToRoute('create-module', moduleId);
      },
      editTags: function editTags(moduleId, moduleTags, moduleName, moduleDesc, selectedModuleThumbnail, module) {
        this.set('selectedModuleId', moduleId);
        this.set('selectedModuleTags', moduleTags);
        this.set('selectedModuleName', moduleName);
        this.set('selectedmoduleDescription', moduleDesc);
        this.set('selectedModuleThumbnail', selectedModuleThumbnail);
        this.set('hideSaveButtons', module.get('hide_save_buttons'));
        $('.hover_bkgr_fricc-module-tags').show();
      },
      previewModule: function previewModule(moduleId, nodeId) {
        this.transitionToRoute('modulePreview', moduleId, {
          queryParams: {
            showNext: true,
            'nodeId': nodeId
          }
        });
      },
      orderBy: function orderBy() {
        var e = document.getElementById('order_by_modules');
        var option = e.options[e.selectedIndex].value;
        if (option == 1) {
          $('#module_search_input').attr('placeholder', 'Search Modules by Name');
        } else if (option == 2) {
          $('#module_search_input').attr('placeholder', 'Eg: Happiness, Anxiety');
        } else if (option == 3) {
          $('#module_search_input').attr('placeholder', 'Eg: MM/DD/YYYY');
        } else {
          $('#module_search_input').attr('placeholder', 'In Progress or Complete');
        }
        this.send('moduleSearch');
      },
      moduleParticipantSearch: function moduleParticipantSearch() {
        var that = this;
        var module_search = this.get('module_search');
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          if (module_search) {
            var a = that.modules.filter(function (e) {
              var module = e.get('module');
              var mod_description = module.get('mod_description');
              return mod_description.toLowerCase().includes(module_search.toLowerCase());
            });
            that.set('modules', a);
          } else {
            that.set('modules', that.model.modules);
          }
        }, 1000);
      },
      moduleSearch: function moduleSearch() {
        var that = this;
        var module_search = this.get('module_search');
        var e = document.getElementById('order_by_modules');
        var option = e.options[e.selectedIndex].value;
        if (option == 3) {
          this.autoFillDate(module_search);
        }
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          if (module_search) {
            that.set('modules', []);
            that.send('getPaginatedModules');
          } else {
            that.set('pageNumber', 1);
            that.send('getPaginatedModules');
          }
        }, 1000);
      },
      getPaginatedModules: function getPaginatedModules() {
        var that = this;
        var pagefilter = this.pagefilter;
        var page = that.pageNumber;
        var numberOfRecords = this.getRecordCount();
        var module_search = this.get('module_search');
        var e = document.getElementById('order_by_modules');
        var option = e.options[e.selectedIndex].value;
        var name__icontains = "";
        var tags = "";
        if (option == 1 || option == "selin") {
          name__icontains = module_search;
        } else if (option == 2) {
          tags = module_search;
        }
        that.store.query('module', {
          page: that.pageNumber,
          current_page: pagefilter,
          on_boarding: pagefilter,
          id_or_name: name__icontains,
          tags: tags,
          page_size: numberOfRecords
        }).then(function (modules) {
          that.set('modules', modules);
          that.set('isNextAvailable', modules.meta.next);
          that.set('isPreviousAvailable', modules.meta.previous);
          that.set('totalModules', modules.meta.count);
          that.set('totalViewableModules_start', page * numberOfRecords - numberOfRecords + 1);
          if (modules.meta.count < numberOfRecords) {
            that.set('totalViewableModules_end', modules.meta.count);
          } else {
            that.set('totalViewableModules_end', page * numberOfRecords);
          }
        });
      },
      previousModules: function previousModules() {
        this.pageNumber = this.pageNumber - 1;
        var module_search = this.get('module_search');
        if (module_search) {
          this.send('moduleSearch');
        } else {
          this.send('getPaginatedModules');
        }
      },
      nextModules: function nextModules() {
        this.pageNumber = this.pageNumber + 1;
        var module_search = this.get('module_search');
        if (module_search) {
          this.send('moduleSearch');
        } else {
          this.send('getPaginatedModules');
        }
      },
      /* Delete Component */
      closeDeleteComponent: function closeDeleteComponent() {
        $('.hover_bkgr_fricc-delete-comp').hide();
        this.set('deleteComponentId', "");
        this.set('deleteComponentName', "");
        this.set('deleteComponentTitle', "");
      },
      openDeleteComponent: function openDeleteComponent(text, id, name) {
        this.set('deleteComponentName', text);
        this.set('deleteComponentTitle', name);
        this.set('deleteComponentId', id);
        $('.hover_bkgr_fricc-delete-comp').show();
      },
      deleteComponentFromApi: function deleteComponentFromApi() {
        var that = this;
        var token = that.get('session.data.authenticated.access_token');
        var deleteComponentId = this.deleteComponentId;
        $.ajax({
          url: _environment.default.APP.API_HOST + '/modules/' + deleteComponentId,
          headers: {
            Authorization: 'Bearer ' + token
          },
          type: 'DELETE',
          success: function success(res) {
            that.send('refreshCurrentRoute');
            $('.hover_bkgr_fricc-delete-comp').hide();
            that.set('deleteComponentId', "");
            that.set('deleteComponentName', "");
            that.set('deleteComponentTitle', "");
          },
          error: function error(err, xH) {
            alert(err.responseText);
          }
        });
      },
      refreshRouter: function refreshRouter() {
        this.send('refreshCurrentRoute');
      }
    },
    autoFillDate: function autoFillDate(module_search) {
      var input = module_search;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      var values = input.split('/').map(function (v) {
        return v.replace(/\D/g, '');
      });
      if (values[0]) values[0] = this.checkValue(values[0], 12);
      if (values[1]) values[1] = this.checkValue(values[1], 31);
      var output = values.map(function (v, i) {
        return v.length == 2 && i < 2 ? v + ' / ' : v;
      });
      var value = output.join('').substr(0, 14);
      this.set('module_search', value);
    },
    checkValue: function checkValue(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    getRecordCount: function getRecordCount() {
      var count = 15;
      var mq1 = window.matchMedia("(min-width: 500px)");
      if (mq1.matches) {
        count = 16;
      }
      var mq2 = window.matchMedia("(min-width: 1350px)");
      if (mq2.matches) {
        count = 15;
      }
      var mq3 = window.matchMedia("(min-width: 1680px)");
      if (mq3.matches) {
        count = 18;
      }
      return count;
    }
  });
});