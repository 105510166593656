define("momentumhub/models/module", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    identifier: _emberData.default.attr(),
    status: _emberData.default.attr(),
    date_created: _emberData.default.attr(),
    date_updated: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    mod_description: _emberData.default.attr(),
    thumbnail_image: _emberData.default.attr(),
    current_page: _emberData.default.attr('boolean'),
    on_boarding: _emberData.default.attr('boolean'),
    hide_save_buttons: _emberData.default.attr('boolean')
  });
});