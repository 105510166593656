define("momentumhub/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M2X2YMO8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"ResetPassword\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"reset-password-component container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner-box\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"logo\"],[12],[13],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"head\"],[12],[2,\"Reset Password\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"login\"],[12],[2,\"\\n            \"],[1,[30,[36,2],null,[[\"placeholder\",\"value\",\"type\"],[\"Password\",[35,1],\"password\"]]]],[2,\"\\n            \"],[1,[30,[36,2],null,[[\"placeholder\",\"value\",\"type\"],[\"Confirm Password\",[35,3],\"password\"]]]],[2,\"\\n            \"],[11,\"div\"],[24,0,\"update-button\"],[4,[38,4],[[32,0],\"resetPassword\"],null],[12],[2,\"Reset Password\"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,5]],[2,\"\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"password\",\"input\",\"confirm_password\",\"action\",\"footer-component\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/reset-password.hbs"
  });
});