define("momentumhub/routes/surveys", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "momentumhub/mixins/non-participant-mixin"], function (_exports, _authenticatedRouteMixin, _environment, _nonParticipantMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _nonParticipantMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      // return RSVP.hash({
      //     surveys : this.store.query('survey', {
      //           page: 1,
      //           test:false
      //       }),
      // });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("surveys").set('survey_search', "");
      this.controllerFor('surveys').send('getPaginatedSurveys');
      this.controllerFor("surveys").set('pageNumber', 1);
    }
  });
});