define("momentumhub/templates/feelings-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hE9/Tr9e",
    "block": "{\"symbols\":[\"diary\"],\"statements\":[[1,[30,[36,1],[\"Feeling Tracker\"],null]],[2,\"\\n\\n\"],[2,\"\\n\"],[10,\"div\"],[14,0,\"feelings-tracker\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Feelings tracker\"],[13],[2,\"\\n\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"isHeader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            Feelings Tracker\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"page-title\",\"diary\"]}",
    "moduleName": "momentumhub/templates/feelings-tracker.hbs"
  });
});