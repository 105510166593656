define("momentumhub/routes/reset-password", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model(param) {
      var uid = param.id;
      var token = param.token;
      return Ember.RSVP.hash({
        uid: uid,
        token: token
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var uid = model.uid;
      var token = model.token;
      this.controllerFor("reset-password").set('uid', uid);
      this.controllerFor("reset-password").set('token', token);
    }
  });
});