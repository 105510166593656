define("momentumhub/routes/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (this.session.isAuthenticated) {
        this.get('router').transitionTo('dashboard');
      } else {
        this.controllerFor("application").set('isParticipant', false);
      }
    }
  });
});