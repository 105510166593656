define("momentumhub/mixins/guardian-session-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var userType = this.get('session.data.user_type');
      var guardianUserTypes = ['PR'];
      if (guardianUserTypes.includes(userType)) {
        this.get('router').transitionTo('dashboard');
      }
    }
  });
});