define("momentumhub/routes/registration", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model(param) {
      this.controllerFor("registration").set('studyId', param.studyId);
      this.controllerFor("registration").set('groupId', param.groupId);
      this.checkIfUserAuthenticated();
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    // Check if user Authenticated and then get user id and age
    checkIfUserAuthenticated: function checkIfUserAuthenticated() {
      var isAuthenticated = this.session.isAuthenticated;
      var that = this;
      if (isAuthenticated) {
        var user_email = this.session.get('data.user_email');
        this.store.query('user', {
          email__iexact: user_email
        }).then(function (users) {
          if (users.length > 0) {
            var user = users.get('firstObject');
            var userId = user.get('id');
            var age = user.get('age');
            var has_completed_onboarding = user.get('has_completed_onboarding');
            that.controllerFor("registration").createGroupInstance(userId, age, has_completed_onboarding);
          }
        });
      }
    }
  });
});