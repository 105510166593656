define("momentumhub/components/worry-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    worryLevel: 0,
    worryHoverLevel: 0,
    allLevels: Array(),
    init: function init() {
      this._super.apply(this, arguments);
      var startValue = 10;
      var endValue = 0;
      var finalAngle = 120;
      var levels = Array();
      var count = startValue - endValue + 1;
      var angleDivider = count;
      for (var i = startValue; i >= endValue; i--) {
        levels.push([count - 1, finalAngle, finalAngle * -1, i]);
        finalAngle -= 270 / angleDivider;
        count--;
      }
      this.set('allLevels', levels);
      this.setNeedleRotate(levels[startValue - this.get('worryLevel')]);
    },
    setNeedleRotate: function setNeedleRotate(level) {
      var angle = level[1] + 88;
      this.set('needleRotateAngle', angle);
    },
    actions: {
      setRating: function setRating(level) {
        this.set('worryLevel', level[0]);
        this.setNeedleRotate(level);
      }
    }
  });
});