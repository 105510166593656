define("momentumhub/models/userevent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    event: _emberData.default.belongsTo('event'),
    user: _emberData.default.belongsTo('user'),
    marked_as_read: _emberData.default.attr('boolean'),
    get_event_message: _emberData.default.attr()
  });
});