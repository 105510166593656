define("momentumhub/controllers/usermanagement/groups", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    researchergroups: [],
    previousPage: "",
    nextPrevious: "",
    page: 1,
    totalPages: 1,
    search: "",
    delayTimer: "",
    groupselected: [],
    participantSelected: false,
    user_type: "",
    researcherAccess: true,
    sites: [],
    canEdit: true,
    actions: {
      openCreateGroup: function openCreateGroup() {
        var that = this;
        that.set('sites', []);
        var user_email = this.session.get('data.user_email');
        $('.hover_bkgr_fricc-group-comp').show();
        this.store.query('user', {
          email: user_email
        }).then(function (users) {
          var user = users.get('firstObject');
          var sites = user.get('get_all_available_sites');
          sites.forEach(function (site) {
            that.store.findRecord('mommentumhubsite', site).then(function (s) {
              that.sites.pushObject(s);
            });
          });
        });
      },
      closeCreateGroup: function closeCreateGroup() {
        $('.hover_bkgr_fricc-group-comp').hide();
      },
      updateGroup: function updateGroup(groupselected) {
        this.set('groupselected', groupselected);
        $('.hover_bkgr_fricc-researchersgroup-comp').show();
      },
      refreshRouter: function refreshRouter() {
        this.getGroups();
      },
      //******************** Create Group ************ */
      createGroup: function createGroup() {
        var accessToken = this.get('session.data.authenticated.access_token');
        var userId = this.session.get('data.userId');
        var that = this;
        var groupName = this.get('groupName');
        var e = document.getElementById("selectsite");
        var selectsite = e.options[e.selectedIndex].value;
        if (groupName) {
          if (selectsite != "def") {
            var isActive = $('#isActive').is(':checked');

            // For Participants
            if (this.participantSelected) {
              var data_to_api = {
                "name": groupName,
                "active": isActive,
                "creator": userId,
                "site": selectsite
              };
              $.ajax({
                url: _environment.default.APP.API_HOST + '/participantgroups',
                type: 'POST',
                data: data_to_api,
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                success: function success(res) {
                  that.set('groupName', "");
                  $('#isActive').prop('checked', false);
                  $('.hover_bkgr_fricc-group-comp').hide();
                  that.newAlert("Group Created successfully.", "Group", "Success");
                  that.getGroups();
                },
                error: function error(err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                  that.newAlert(str, "Add User", "Failure");
                }
              });
            }

            // For researchers
            else {
              var data_to_api = {
                "name": groupName,
                "active": true,
                "creator": userId,
                "site": selectsite
              };
              $.ajax({
                url: _environment.default.APP.API_HOST + '/researchergroups',
                type: 'POST',
                data: data_to_api,
                headers: {
                  "Authorization": "Bearer " + accessToken
                },
                success: function success(res) {
                  that.set('groupName', "");
                  $('.hover_bkgr_fricc-group-comp').hide();
                  that.newAlert("Group Created successfully.", "Group", "Success");
                  that.getGroups();
                },
                error: function error(err, xH) {
                  var str = err.responseText;
                  str = str.split(':["').pop();
                  str = str.split('"]')[0];
                  that.newAlert(str, "Add User", "Failure");
                }
              });
            }
          } else {
            this.showError("selectsite");
          }
        } else {
          this.showError("groupName");
        }
      },
      activatedeactivategroup: function activatedeactivategroup(status, id) {
        var accessToken = this.get('session.data.authenticated.access_token');
        var st = "";
        if (status) {
          st = "Activate";
        } else {
          st = "Deactivate";
        }
        var r = confirm("Are you sure you want to " + st + " group ?");
        if (r) {
          var that = this;
          var data_to_api = {
            "active": status
          };

          // For Participants
          if (this.participantSelected) {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/participantgroups/' + id,
              type: 'PATCH',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function success(res) {
                $('.hover_bkgr_fricc-group-comp').hide();
                that.newAlert("Group status updated successfully.", "Group", "Success");
                that.getGroups();
              },
              error: function error(err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                that.newAlert(str, "Add User", "Failure");
              }
            });
          }

          // For researchers
          else {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/researchergroups/' + id,
              type: 'PATCH',
              data: data_to_api,
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function success(res) {
                $('.hover_bkgr_fricc-group-comp').hide();
                that.newAlert("Group status updated successfully.", "Group", "Success");
                that.getGroups();
              },
              error: function error(err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                that.newAlert(str, "Add User", "Failure");
              }
            });
          }
        }
      },
      searchGroup: function searchGroup() {
        var that = this;
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.getGroups();
        }, 1000);
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getGroups();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getGroups();
      },
      //**************** Delete Group ************ */
      deleteGroup: function deleteGroup(researchergroup) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        // For Participants
        if (this.participantSelected) {
          var r = confirm("Are you sure you want to delete this site ?");
          if (r) {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/participantgroups/' + researchergroup.id,
              type: 'DELETE',
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function success(res) {
                that.newAlert("Group Deleted successfully.", "Group", "Success");
                that.getGroups();
              },
              error: function error(err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                that.newAlert(str, "Add User", "Failure");
              }
            });
          }
        } else {
          var _r = confirm("Are you sure you want to delete this site ?");
          if (_r) {
            $.ajax({
              url: _environment.default.APP.API_HOST + '/researchergroups/' + researchergroup.id,
              type: 'DELETE',
              headers: {
                "Authorization": "Bearer " + accessToken
              },
              success: function success(res) {
                that.newAlert("Group Deleted successfully.", "Group", "Success");
                that.send('refreshCurrentRoute');
              },
              error: function error(err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                that.newAlert(str, "Add User", "Failure");
              }
            });
          }
        }
      },
      chooseUser: function chooseUser(type) {
        if (type == "researcher") {
          this.set('participantSelected', false);
          $('.res').css({
            "background-color": '#344D90'
          });
          $('.res').css({
            "color": 'white'
          });
          $('.pt').css({
            "background-color": 'white'
          });
          $('.pt').css({
            "color": 'black'
          });
          if (this.researcherAccess) {
            this.set('canEdit', false);
          }
        } else {
          this.set('participantSelected', true);
          $('.pt').css({
            "background-color": '#344D90'
          });
          $('.pt').css({
            "color": 'white'
          });
          $('.res').css({
            "background-color": 'white'
          });
          $('.res').css({
            "color": 'black'
          });
          if (this.researcherAccess) {
            this.set('canEdit', true);
          }
        }
        this.set('page', 1);
        this.getGroups();
      }
    },
    showError: function showError(id) {
      $("#" + id).addClass("error");
      setTimeout(function () {
        $("#" + id).removeClass("error");
      }, 2000);
    },
    //***************** Get Groups ******** */
    getGroups: function getGroups() {
      var that = this;
      that.set('researchergroups', []);
      var search = this.get('search');
      if (this.participantSelected) {
        this.store.query('participantgroup', {
          page: that.page,
          name__icontains: search
        }).then(function (researchergroups) {
          that.set('researchergroups', researchergroups);
          that.set('previousPage', researchergroups.meta.previous);
          that.set('nextPrevious', researchergroups.meta.next);
          if (researchergroups.meta.count) {
            var totalPages = researchergroups.meta.count / 15;
            if (totalPages % 1 != 0) {
              that.set('totalPages', parseInt(totalPages) + 1);
            } else {
              that.set('totalPages', parseInt(totalPages));
            }
          }
        });
      } else {
        this.store.query('researchergroup', {
          page: that.page,
          name__icontains: search
        }).then(function (researchergroups) {
          that.set('researchergroups', researchergroups);
          that.set('previousPage', researchergroups.meta.previous);
          that.set('nextPrevious', researchergroups.meta.next);
          if (researchergroups.meta.count) {
            var totalPages = researchergroups.meta.count / 15;
            if (totalPages % 1 != 0) {
              that.set('totalPages', parseInt(totalPages) + 1);
            } else {
              that.set('totalPages', parseInt(totalPages));
            }
          }
        });
      }
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
});