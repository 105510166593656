define("momentumhub/routes/change-focus", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-knockout", "momentumhub/helpers/custom-widgets", "survey-pdf", "blockly/msg/en"], function (_exports, _authenticatedRouteMixin, _environment, Survey, _customWidgets, SurveyPDF, _en) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    init: function init() {
      this._super.apply(this, arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
    },
    model: function model(param) {
      var viewingId = this.session.get('data.viewingId');
      var survey = _environment.default.APP.DIARY_CHANGEFOCUS_CHILD;
      if (this.get('isTeenager')) survey = _environment.default.APP.DIARY_CHANGEFOCUS_TEEN;
      return Ember.RSVP.hash({
        survey: this.store.query('survey', {
          identifier: survey
        }),
        activity: this.store.query('activity', {
          identifier: survey,
          user: viewingId
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});