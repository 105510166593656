define("momentumhub/templates/survey-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W2GWKOq+",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"SurveyPreview\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"button-container\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"backBtn\",[32,0,[\"model\",\"survey_id\"]]],null],[12],[2,\" \"],[1,[30,[36,2],[\"chevron-left\"],[[\"class\"],[\"back-icon\"]]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"survey-container survey-container12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"survey-component survey-preview-component\"],[14,1,\"surveyElement\"],[12],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,1,\"surveyResult\"],[12],[13],[2,\"\\n\"],[13],[2,\" \\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"action\",\"fa-icon\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/survey-preview.hbs"
  });
});