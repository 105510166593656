define("momentumhub/templates/components/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Xc0TF8p6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"landing-page-component\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"landing-page-logo bounce\"],[14,\"src\",\"/assets/participantDashboard/logo1.svg\"],[14,\"alt\",\"Momentum Hub\"],[12],[13],[2,\"\\n\\n    \"],[3,\"\\n    <div class=\\\"middle-section\\\">\\n        <div class=\\\"row\\\">\\n            <h1 class=\\\"head\\\">Lauren ipsum heading</h1>\\n            <p class=\\\"middle-copy\\\">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>\\n        </div>\\n        <div class=\\\"row\\\">\\n            <div class=\\\"globalbutton signup\\\"> <a href=\\\"/\\\">Return to Home Page</a></div>\\n        </div>\\n    </div>\\n    \"],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"announcements\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"announcement-heading\"],[12],[2,\"Thanks for using Momentum\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"announcement-copy\"],[12],[2,\"Click here to return to the main webpage:\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"globalbutton\"],[14,5,\"margin: 0 auto; width:255px;\"],[12],[2,\" \"],[10,\"a\"],[14,6,\"/\"],[12],[2,\"Return to Home Page\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/templates/components/landing-page.hbs"
  });
});