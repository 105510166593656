define("momentumhub/controllers/onboarding-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var onboardingDetails = localStorage.getItem('onboardingDetails');
      if (onboardingDetails) {
        onboardingDetails = JSON.parse(onboardingDetails);
        var url = '/onboarding-confirmation/' + onboardingDetails.studyId + '/' + onboardingDetails.groupId;
        // this.transitionToRoute('onboarding-new', onboardingDetails.studyId, onboardingDetails.groupId, {queryParams: {showConsentForm: true}});
        this.transitionToRoute(url);
      }
    }
  });
});