define("momentumhub/controllers/module-preview", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var _default = _exports.default = Ember.Controller.extend({
    hedgeDocUrl: "",
    session: Ember.inject.service(),
    isLastPage: false,
    hasNext: false,
    showNext: false,
    module: null,
    next_node: "",
    current_node: "",
    studyPartJson: [],
    actions: {
      nextButton: function nextButton() {
        var nextNodeID = this.getNextNode(this.current_node, this.studyPartJson);
        var continuetoNextNode = this.studyPartJson.filter(function (e) {
          return e.id == nextNodeID;
        });
        if (continuetoNextNode.length > 0) {
          this.transitionToRoute('modulePreview', continuetoNextNode[0].typeid, {
            queryParams: {
              showNext: this.get('showNext'),
              'nodeId': nextNodeID
            }
          });
        }
      },
      endOfModule: function endOfModule() {
        if (!this.get('module.hide_save_buttons')) {
          this.set('isLastPage', true);
        }
      },
      nextSurvey: function nextSurvey() {
        var surveyId = localStorage.getItem("hedgedocsurveyresult");
        var result = localStorage.getItem("hedgedocsurveyresult1");
        localStorage.setItem("hedgedocsurveyresult", "");
        localStorage.setItem("hedgedocsurveyresult1", "");
        var that = this;
        this.store.findRecord('survey', surveyId).then(function (survey) {
          // const json = survey.get('survey_body_viewable');
          var survey_body = survey.get('survey_body');
          var surveyObj = new Survey.Model(survey.get('survey_body_viewable'));
          result = JSON.parse(result);
          for (var _i = 0, _Object$entries = Object.entries(result); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];
            surveyObj.setValue(key, value);
          }
          that.saveSurveyResult(surveyObj, survey_body, surveyId, "InModule", survey_body);
        });
      },
      exitModule: function exitModule() {
        this.set('isLastPage', false);
        var lastUrl = localStorage.getItem('previousUrl');
        if (lastUrl) {
          this.transitionToRoute(lastUrl);
        } else {
          window.history.back();
        }
        // this.transitionToRoute('modules');
      }
    },
    getNextNode: function getNextNode(currentNode, part) {
      //1. Get current node's object
      var currentNodeObjectArray = part.filter(function (e) {
        return e.id == currentNode;
      });
      if (currentNodeObjectArray.length > 0) {
        var currentNodeObject = currentNodeObjectArray[0];
        var isnextBranch = currentNodeObject.branch;
        var next = currentNodeObject.next;
        var totalBranches = currentNodeObject.totalBranches;

        //2. If there is a next node
        if (totalBranches > 0) {
          // 2.1. if next node is a linear node then return next node id
          if (isnextBranch == 0) {
            return next;
          }

          //2.2. Else next node is a branch then find a node with status NTT
          else {
            var nextNodeObjectArray = part.filter(function (e) {
              return e.status === "NTT";
            });
            if (nextNodeObjectArray.length > 0) {
              var nextNodeObject = nextNodeObjectArray[0];
              var nextId = nextNodeObject.id;
              return nextId;
            }
          }
        }
        //3. No next node
        else {
          return "PART COMPLETE";
        }
      }
    },
    // Save Survey results
    saveSurveyResult: function saveSurveyResult(survey, json, surveyId, type, survey_body_json) {
      var result = survey.data;
      var that = this;
      var finalResultArray = [];
      var displayValues = [];
      for (var key in survey.data) {
        var question = survey.getQuestionByValueName(key);
        var mappings = {};
        if (!question) continue;
        if (question.classMetaData.name == 'matrix') question.rows.map(function (row, index) {
          mappings[row.id] = row.title;
        });
        var questionResult = {
          name: key,
          value: survey.data[key],
          title: question.title,
          displayValue: question.displayValue,
          questionLabel: mappings
        };
        displayValues.push(questionResult);
      }
      json.pages.forEach(function (page) {
        var _loop = function _loop() {
            var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
              key = _Object$entries2$_i[0],
              value = _Object$entries2$_i[1];
            var a = page.elements.filter(function (e) {
              return e.name == key;
            });
            if (a[0]) {
              var b = a[0];

              // 1.Checkbox
              if (b.type == "checkbox") {
                choicesArray = [];
                value.forEach(function (val) {
                  var c = b.choices.filter(function (e) {
                    return e.value == val;
                  });
                  if (c[0]) {
                    var text = c[0].text;
                    text = that.checkString(text);
                    choicesArray.pushObject(text);
                  }
                });
                var obj = {
                  "question": key,
                  "answer": choicesArray
                };
                finalResultArray.pushObject(obj);
              }

              // 2.radiogroup
              if (b.type == "radiogroup" || b.type == 'randomselect' || b.type == 'quiz') {
                var c = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (c[0]) {
                  text = c[0].text;
                  text = that.checkString(text);
                  var _obj = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj);
                }
              }

              //3. Dropdown
              if (b.type == "dropdown") {
                var _c = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (_c[0]) {
                  text = _c[0].text;
                  text = that.checkString(text);
                  var _obj2 = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj2);
                }
              }

              //4. Boolean
              if (b.type == "boolean") {
                if (value == true) {
                  text = b.labelTrue;
                  text = that.checkString(text);
                  var _obj3 = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj3);
                } else {
                  text = b.labelFalse;
                  text = that.checkString(text);
                  var _obj4 = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj4);
                }
              }

              //5. Ratings
              if (b.type == "rating") {
                var _c2 = b.rateValues.filter(function (e) {
                  return e.value == value;
                });
                if (_c2[0]) {
                  text = _c2[0].text;
                  text = that.checkString(text);
                  var _obj5 = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj5);
                }
              }

              //6. Ranking
              if (b.type == "ranking") {
                var _obj6 = {
                  "question": key,
                  "answer": value
                };
                finalResultArray.pushObject(_obj6);
              }

              //7. Simple Text
              if (b.type == "text") {
                var _obj7 = {
                  "question": key,
                  "answer": value
                };
                finalResultArray.pushObject(_obj7);
              }

              //8. matrix
              if (b.type == "matrix") {
                matrixArray = [];
                var _loop2 = function _loop2() {
                  var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i3], 2),
                    k = _Object$entries3$_i[0],
                    v = _Object$entries3$_i[1];
                  columnText = "";
                  rowText = "";
                  cv = b.columns.filter(function (e) {
                    return e.value == v;
                  });
                  if (cv[0]) {
                    columnText = cv[0].text;
                  }
                  rv = b.rows.filter(function (e) {
                    return e.value == k;
                  });
                  if (rv[0]) {
                    rowText = rv[0].text;
                  }
                  if (columnText.includes("~")) {
                    fields = columnText.split('~ val:');
                    columnText = fields[1];
                  }
                  if (rowText.includes("~")) {
                    fields = rowText.split('~ val:');
                    rowText = fields[1];
                  }
                  if (!isNaN(parseInt(columnText))) {
                    matrixArray.pushObject(rowText + ":" + columnText);
                  }
                };
                for (var _i3 = 0, _Object$entries3 = Object.entries(value); _i3 < _Object$entries3.length; _i3++) {
                  _loop2();
                }
                var _obj8 = {
                  "question": key,
                  "answer": matrixArray
                };
                finalResultArray.pushObject(_obj8);
              }

              //9. ImagePicker
              if (b.type == "imagepicker") {
                var _c3 = b.choices.filter(function (e) {
                  return e.value == value;
                });
                if (_c3[0]) {
                  text = _c3[0].text;
                  text = that.checkString(text);
                  var _obj9 = {
                    "question": key,
                    "answer": text
                  };
                  finalResultArray.pushObject(_obj9);
                }
              }

              //10. matrixdropdown
              if (b.type == "matrixdropdown") {
                matrixArray = [];
                for (var _i4 = 0, _Object$entries4 = Object.entries(value); _i4 < _Object$entries4.length; _i4++) {
                  var _Object$entries4$_i = _slicedToArray(_Object$entries4[_i4], 2),
                    k = _Object$entries4$_i[0],
                    v = _Object$entries4$_i[1];
                  rowIndex = k[4];
                  rowText = b.rows[parseInt(rowIndex) - 1].text;
                  rowColumn = "";
                  if (rowText.includes("~")) {
                    fields = rowText.split('~ val:');
                    rowText = fields[1];
                  }
                  //Columns
                  for (var _i5 = 0, _Object$entries5 = Object.entries(v); _i5 < _Object$entries5.length; _i5++) {
                    var _Object$entries5$_i = _slicedToArray(_Object$entries5[_i5], 2),
                      k1 = _Object$entries5$_i[0],
                      v1 = _Object$entries5$_i[1];
                    columnIndex = k1[7];
                    columnText = b.columns[parseInt(columnIndex) - 1].title;
                    if (columnText.includes("~")) {
                      fields = columnText.split('~ val:');
                      columnText = fields[1];
                      rowColumn = rowText + "_" + columnText;
                      matrixArray.pushObject(rowColumn + ":" + v1);
                    } else {
                      if (b.choices[v1 - 1]) {
                        var choice = b.choices[v1 - 1].text;
                        if (choice.includes("~")) {
                          fields = choice.split('~ val:');
                          rowColumn = rowText + "_" + columnText;
                          matrixArray.pushObject(rowColumn + ":" + fields[1]);
                        }
                      }
                    }
                  }
                }
                var _obj10 = {
                  "question": key,
                  "answer": matrixArray
                };
                finalResultArray.pushObject(_obj10);
              }
              if (b.type == "expression") {
                title = b.title;
                title = title.split('~')[1];
                var answer = title + ":" + value;
                var _obj11 = {
                  "question": key,
                  "answer": answer
                };
                finalResultArray.pushObject(_obj11);
              }
              if (b.type == "ratinggauge") {
                title = b.title;
                title = title.split('~')[1];
                var _answer = title + ":" + value;
                var _obj12 = {
                  "question": key,
                  "answer": _answer
                };
                finalResultArray.pushObject(_obj12);
              }
            }
          },
          choicesArray,
          text,
          text,
          text,
          text,
          text,
          matrixArray,
          columnText,
          rowText,
          cv,
          rv,
          fields,
          fields,
          text,
          matrixArray,
          rowIndex,
          rowText,
          rowColumn,
          fields,
          columnIndex,
          columnText,
          fields,
          fields,
          title,
          title;
        for (var _i2 = 0, _Object$entries2 = Object.entries(result); _i2 < _Object$entries2.length; _i2++) {
          _loop();
        }
        console.log(finalResultArray);
        displayValues.reverse();
        finalResultArray.reverse();
        that.saveSurveyActivity(finalResultArray, surveyId, type, result, displayValues);
      });
    },
    checkString: function checkString(text) {
      if (text) {
        if (text.includes("~")) {
          var fields = text.split('~');
          return fields[1];
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    saveSurveyActivity: function saveSurveyActivity(result_array, survey, type, result_json, displayValues) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var user = this.get('session.data.userId');
      var data_to_api = {
        "user": user,
        "survey": survey,
        "result_array": JSON.stringify(result_array),
        "result_json": JSON.stringify(result_json),
        "display_values": JSON.stringify(displayValues)
      };
      $.ajax({
        url: _environment.default.APP.API_HOST + '/activities',
        type: 'POST',
        data: data_to_api,
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          console.log("successfull");
        },
        error: function error(err, xH) {
          var str = err.responseText;
          console.log(str);
        }
      });
    }
  });
});