define("momentumhub/controllers/usermanagement/studies", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    studyArray: [],
    previousPage: "",
    nextPrevious: "",
    page: 1,
    totalPages: 1,
    search: "",
    delayTimer: "",
    studySelected: [],
    user_type: "",
    researcherAccess: true,
    currentUser: [],
    participantSelected: false,
    showArchived: false,
    buttonColor: '#8b8b8b',
    actions: {
      updateStudy: function updateStudy(studySelected) {
        this.set('studySelected', studySelected);
        $('.hover_bkgr_fricc-assigngroups-comp').show();
      },
      refreshRouter: function refreshRouter() {
        this.getStudies();
      },
      openCreateStudyComponent: function openCreateStudyComponent() {
        $('.hover_bkgr_fricc-study-comp').show();
      },
      searchStudies: function searchStudies() {
        var that = this;
        clearTimeout(this.delayTimer);
        that.set('page', 1);
        that.delayTimer = setTimeout(function () {
          that.getStudies();
        }, 1000);
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getStudies();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getStudies();
      },
      chooseUser: function chooseUser(type) {
        if (type == "researcher") {
          this.set('participantSelected', false);
          $('.res').css({
            "background-color": '#344D90'
          });
          $('.res').css({
            "color": 'white'
          });
          $('.pt').css({
            "background-color": 'white'
          });
          $('.pt').css({
            "color": 'black'
          });
        } else {
          this.set('participantSelected', true);
          $('.pt').css({
            "background-color": '#344D90'
          });
          $('.pt').css({
            "color": 'white'
          });
          $('.res').css({
            "background-color": 'white'
          });
          $('.res').css({
            "color": 'black'
          });
        }
        this.set('page', 1);
      },
      //Copy to the clipb board
      copyToTheClipboard: function copyToTheClipboard(createdLink) {
        var $temp = $("<input>");
        $("body").append($temp);
        var t = createdLink;
        t = t.trim();
        $temp.val(t).select();
        document.execCommand("copy");
        $temp.remove();
        this.newAlert("Text copied to clipboard", "Text copied", "Success");
      },
      deleteStudy: function deleteStudy(id, study_name) {
        var that = this;
        var accessToken = this.get('session.data.authenticated.access_token');
        var r = confirm('You will lose all the study data if you delete "' + study_name + '". Do you still want to proceed?');
        if (r) {
          $.ajax({
            url: _environment.default.APP.API_HOST + '/studies/' + id,
            type: 'PATCH',
            data: {
              'active': false,
              'archived': true,
              'public_to_all_participants': false
            },
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function success(res) {
              that.newAlert("Study Deleted successfully.", "Study", "Success");
              that.getStudies();
            },
            error: function error(err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Delete Study", "Failure");
            }
          });
        }
      },
      showArchivedStudy: function showArchivedStudy() {
        this.set('showArchived', !this.showArchived);
        if (this.showArchived) {
          this.set('buttonColor', '#344D90');
        } else {
          this.set('buttonColor', '#8b8b8b');
        }
        this.getStudies();
      }
    },
    showError: function showError(id) {
      $("#" + id).addClass("error");
      setTimeout(function () {
        $("#" + id).removeClass("error");
      }, 2000);
    },
    //***************** Get Studies ******** */
    getStudies: function getStudies() {
      var that = this;
      that.set('studyArray', []);
      var search = this.get('search');
      var showArchived = that.get('showArchived');
      that.store.query('study', {
        page: that.page,
        archived: showArchived,
        search: search
      }).then(function (studies) {
        that.set('studyArray', studies.toArray());
        that.set('previousPage', studies.meta.previous);
        that.set('nextPrevious', studies.meta.next);
        if (studies.meta.count) {
          var totalPages = studies.meta.count / 15;
          if (totalPages % 1 != 0) {
            that.set('totalPages', parseInt(totalPages) + 1);
          } else {
            that.set('totalPages', parseInt(totalPages));
          }
        }
      });
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
});