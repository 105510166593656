define("momentumhub/routes/usermanagement/users", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      return Ember.RSVP.hash({});
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("usermanagement.users").getUsers();
      this.setPermissions();
    },
    setPermissions: function setPermissions() {
      var user_type = this.controllerFor("usermanagement.users").get('user_type');
      if (user_type == "PA" || user_type == "SA") {
        this.controllerFor("usermanagement.users").set('researcherAccess', false);
      } else {
        this.controllerFor("usermanagement.users").set('researcherAccess', true);
      }
    }
  });
});