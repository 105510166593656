define("momentumhub/routes/usermanagement/groups", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      return Ember.RSVP.hash({});
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.setPermissions();
      this.controllerFor("usermanagement.groups").getGroups();
    },
    setPermissions: function setPermissions() {
      var user_type = this.controllerFor("usermanagement.groups").get('user_type');
      if (user_type == "PA" || user_type == "SA") {
        this.controllerFor("usermanagement.groups").set('researcherAccess', false);
        this.controllerFor("usermanagement.groups").set('canEdit', true);
        // this.controllerFor("usermanagement.groups").set('participantSelected', false);
      } else {
        this.controllerFor("usermanagement.groups").set('researcherAccess', true);
        this.controllerFor("usermanagement.groups").set('canEdit', false);
        // this.controllerFor("usermanagement.groups").set('participantSelected', true);
      }
      this.controllerFor("usermanagement.groups").set('participantSelected', false);
    }
  });
});