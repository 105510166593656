define("momentumhub/routes/clue-calmers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var modules = [];
      var user_email = this.session.get('data.user_email');
      var viewingId = this.session.get('data.viewingId');
      if (!viewingId || viewingId.length === 0) viewingId = this.session.get('data.userId');
      if (this.get('isTeenager')) {
        modules = [_environment.default.APP.CLUE_CALMERS_TEEN_MOD1, _environment.default.APP.CLUE_CALMERS_TEEN_MOD2, _environment.default.APP.CLUE_CALMERS_TEEN_MOD3, _environment.default.APP.CLUE_CALMERS_TEEN_MOD4];
      } else {
        modules = [_environment.default.APP.CLUE_CALMERS_CHILD_MOD1, _environment.default.APP.CLUE_CALMERS_CHILD_MOD2, _environment.default.APP.CLUE_CALMERS_CHILD_MOD3, _environment.default.APP.CLUE_CALMERS_CHILD_MOD4];
      }
      return Ember.RSVP.hash({
        modules: this.store.query('module', {
          identifiers: modules.join(",")
        }).then(function (results) {
          return results.toArray().sort(function (a, b) {
            return modules.indexOf(a.get('identifier')) - modules.indexOf(b.get('identifier'));
          });
        }),
        survey: this.store.query('survey', {
          identifier: _environment.default.APP.DIARY_CLUECALMERS_FORMID
        }),
        activity: this.store.query('activity', {
          identifier: _environment.default.APP.DIARY_CLUECALMERS_FORMID,
          user: viewingId
        }),
        rewards: this.store.query('userrewardvar', {
          'user': viewingId
        })
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});