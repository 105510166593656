define("momentumhub/controllers/survey-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      backBtn: function backBtn(surveyId) {
        window.history.back();
      }
    }
  });
});