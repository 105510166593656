define("momentumhub/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    users: _emberData.default.attr(),
    message: _emberData.default.attr(),
    send_to_all: _emberData.default.attr('boolean'),
    created_by: _emberData.default.attr(),
    created_at: _emberData.default.attr()
  });
});