define("momentumhub/models/mood-booster-event", ["exports", "ember-data", "momentumhub/utils"], function (_exports, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    day: _emberData.default.attr('number'),
    start: _emberData.default.attr(),
    end: _emberData.default.attr(),
    background_colour: _emberData.default.attr(),
    generateFullCalendarFormat: function generateFullCalendarFormat() {
      return {
        'id': this.get('id'),
        'title': this.get('title'),
        'day': this.get('day'),
        'start': (0, _utils.getDayAndTimeOfCurrentWeek)(this.get('day'), this.get('start')),
        'end': (0, _utils.getDayAndTimeOfCurrentWeek)(this.get('day'), this.get('end')),
        'backgroundColor': this.get('background_colour')
      };
    }
  });
});