define("momentumhub/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _DS$Model$extend;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = _emberData.default.Model.extend((_DS$Model$extend = {
    name: _emberData.default.attr(),
    identifier: _emberData.default.attr(),
    action: _emberData.default.attr(),
    receiver: _emberData.default.attr(),
    sender: _emberData.default.attr(),
    message: _emberData.default.attr(),
    rule: _emberData.default.belongsTo('rule'),
    is_node_event: _emberData.default.attr("boolean"),
    delivery_time: _emberData.default.attr(),
    delivery_timestamp: _emberData.default.attr(),
    delivery_type: _emberData.default.attr(),
    delivery_time_after_creation: _emberData.default.attr(),
    event_fire_frequency: _emberData.default.attr(),
    days_between_fire_frequency: _emberData.default.attr()
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_DS$Model$extend, "delivery_time_after_creation", _emberData.default.attr()), "reward_operation", _emberData.default.attr()), "reward_change", _emberData.default.attr()), "reward_variable", _emberData.default.attr()), "module_id", _emberData.default.attr()), "module_availability", _emberData.default.attr("boolean")), "is_resource", _emberData.default.attr("boolean")), "delay_in_hours", _emberData.default.attr()), "survey_id", _emberData.default.attr()), "report_type", _emberData.default.attr()), _defineProperty(_DS$Model$extend, "notification_settings", _emberData.default.attr())));
});