define("momentumhub/models/activity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    study: _emberData.default.belongsTo('study'),
    user: _emberData.default.belongsTo('user'),
    part: _emberData.default.attr(),
    node: _emberData.default.attr(),
    node_ref: _emberData.default.attr(),
    survey: _emberData.default.belongsTo('survey'),
    results: _emberData.default.attr(),
    timestamp: _emberData.default.attr()
  });
});