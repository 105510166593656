define("momentumhub/controllers/usermanagement/users", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    users: [],
    type: "",
    previousPage: "",
    nextPrevious: "",
    page: 1,
    totalPages: 1,
    user_type_search: "",
    selectedUser: [],
    researcherSites: [],
    choices: [{
      "value": "",
      "display_name": "All"
    }, {
      "value": "PT",
      "display_name": "Participant"
    }, {
      "value": "PA",
      "display_name": "Site Admin"
    }, {
      "value": "RS",
      "display_name": "Researcher"
    },
    // {
    //     "value": "PL",
    //     "display_name": "Professional"
    // },
    {
      "value": "PR",
      "display_name": "Guardian"
    },
    // {
    //     "value": "ST",
    //     "display_name": "Staff"
    // },
    {
      "value": "CL",
      "display_name": "Clinician"
    }],
    choices2: [{
      "value": "",
      "display_name": "All"
    }, {
      "value": "PT",
      "display_name": "Participant"
    }, {
      "value": "PR",
      "display_name": "Guardian"
    }, {
      "value": "CL",
      "display_name": "Clinician"
    }],
    delayTimer: "",
    search: "",
    user_type: "",
    researcherAccess: true,
    actions: {
      createUser: function createUser() {
        this.getSiteAdminSites();
        this.set('type', "add");
        $('.hover_bkgr_fricc-user-comp').show();
      },
      searchUser: function searchUser() {
        var that = this;
        var usersearch = this.get('usersearch');
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.set('search', usersearch);
          that.getUsers();
        }, 1000);
      },
      updateUser: function updateUser(selectedUser) {
        this.getSiteAdminSites();
        this.set('type', "update");
        this.set('selectedUser', selectedUser);
        $('.hover_bkgr_fricc-user-comp').show();
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getUsers();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getUsers();
      },
      selectUserType: function selectUserType() {
        var e = document.getElementById("user_type_search");
        var user_type = e.options[e.selectedIndex].value;
        this.set('user_type_search', user_type);
        this.getUsers();
      },
      refreshRouter: function refreshRouter() {
        this.send('refreshCurrentRoute');
      }
    },
    //********* Get users **********/
    getUsers: function getUsers() {
      var that = this;
      this.store.query('user', {
        page: that.page,
        // all_users: true,
        user_type: that.user_type_search,
        search: that.search
      }).then(function (users) {
        that.set('users', users);
        that.set('previousPage', users.meta.previous);
        that.set('nextPrevious', users.meta.next);
        if (users.meta.count) {
          var totalPages = users.meta.count / 15;
          if (totalPages % 1 != 0) {
            that.set('totalPages', parseInt(totalPages) + 1);
          } else {
            that.set('totalPages', parseInt(totalPages));
          }
        }
      });
    },
    getSiteAdminSites: function getSiteAdminSites() {
      var that = this;
      that.set('researcherSites', []);
      that.set('isPaginationAllowed', false);
      var userEmail = this.session.get('data.user_email');
      this.store.query('user', {
        email__iexact: userEmail
      }).then(function (users) {
        var user = users.get('firstObject');
        var sites = user.get('get_all_available_sites');
        sites.forEach(function (site) {
          that.store.findRecord('mommentumhubsite', site).then(function (s) {
            that.researcherSites.pushObject(s);
          });
        });
      });
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
});