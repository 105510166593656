define("momentumhub/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sEL/QSVX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"head\"],[12],[2,\"\\n    \"],[10,\"meta\"],[14,\"http-equiv\",\"refresh\"],[14,\"content\",\"5\"],[12],[13],[2,\" \\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"not-found\"],[12],[2,\"\\n\\n      \"],[10,\"img\"],[14,0,\"icon bounce\"],[14,\"src\",\"/assets/logo1.svg\"],[14,\"width\",\"100\"],[12],[13],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Page Not Found (404)\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"You maybe seeing this page because the content has not generated yet. This page will autorefresh every 5 seconds\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/templates/not-found.hbs"
  });
});