define("momentumhub/models/survey", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    status: _emberData.default.attr(),
    date_created: _emberData.default.attr(),
    date_updated: _emberData.default.attr(),
    survey_body: _emberData.default.attr(),
    survey_body_viewable: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    description: _emberData.default.attr(),
    lock_assigned_to: _emberData.default.attr(),
    locked: _emberData.default.attr('boolean'),
    identifier: _emberData.default.attr(),
    special_category_key: _emberData.default.attr(),
    allow_print: _emberData.default.attr('boolean')
  });
});