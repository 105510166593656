define("momentumhub/templates/components/survey-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "twwb4JXK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"survey-component\"],[14,1,\"surveyCreatorDivElementID\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/templates/components/survey-creator.hbs"
  });
});