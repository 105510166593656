define("momentumhub/controllers/study-analytics", ["exports", "momentumhub/config/environment", "chart.js/auto"], function (_exports, _environment, _auto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    mobileSide: true,
    searchText: '',
    disableDownloadJson: false,
    page: 1,
    users: [],
    previousPage: '',
    nextPrevious: '',
    totalPages: 0,
    totalUsers: 0,
    type: '',
    researcherAccess: true,
    selectedUser: [],
    init: function init() {
      this._super.apply(this, arguments);
    },
    getUsers: function getUsers() {
      var that = this;
      this.store.query('user', {
        page: that.page,
        roles: 'PT',
        search: that.searchText,
        study: that.get('model.study.id'),
        expand_study_data: true
      }).then(function (users) {
        that.set('users', users);
        that.set('previousPage', users.meta.previous);
        that.set('nextPrevious', users.meta.next);
        if (users.meta.count) {
          var totalPages = users.meta.count / 15;
          if (totalPages % 1 != 0) {
            that.set('totalPages', parseInt(totalPages) + 1);
          } else {
            that.set('totalPages', parseInt(totalPages));
          }
        }
      });
    },
    actions: {
      searchUser: function searchUser() {
        var that = this;
        var usersearch = this.get('usersearch');
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.set('searchText', usersearch);
          that.getUsers();
        }, 1000);
      },
      updateUser: function updateUser(user) {
        var that = this;
        this.set('selectedUser', user);
        this.set('type', 'edit');
        $('.hover_bkgr_fricc-user-comp').show();
      },
      refreshRouter: function refreshRouter() {
        this.send('refreshCurrentRoute');
      },
      downloadJson: function downloadJson() {
        if (this.disableDownloadJson) return;
        this.set('disableDownloadJson', true);
        var studyId = this.get('model.study.id');
        var accessToken = this.get('session.data.authenticated.access_token');
        var that = this;
        var url = _environment.default.APP.API_HOST + '/studies/' + studyId + '/download-user-info';
        $.ajax({
          url: url,
          type: 'GET',
          headers: {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          success: function success(data) {
            var d = [JSON.stringify(data)];
            var blob = new Blob(d, {
              type: 'application/json'
            });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "output.json";
            document.body.appendChild(a);
            a.click();
            that.set('disableDownloadJson', false);
          }
        });
      },
      downloadRScript: function downloadRScript() {
        window.location.href = _environment.default.APP.API_HOST_INITIAL + '/download-analytics-r-script/';
      },
      // Open Messages for Mobile screen
      openleftSide: function openleftSide() {
        if (this.mobileSide) {
          $('.left-side').show();
          this.set('mobileSide', false);
        } else {
          $('.left-side').hide();
          this.set('mobileSide', true);
        }
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getUsers();
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getUsers();
      }
    },
    setupChart: function setupChart() {
      var that = this;
      if (this.userChart) {
        this.userChart.destroy();
      }
      if (this.progressChart) {
        this.progressChart.destroy();
      }
      setTimeout(function () {
        that.drawUserChart();
        that.drawProgressChart();
      }, 1000);
    },
    drawUserChart: function drawUserChart() {
      var ctx = document.getElementById('user-counts');
      var studyId = this.get('model.study.id');
      var accessToken = this.get('session.data.authenticated.access_token');
      var that = this;
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studies/' + studyId + '/user-stats',
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        success: function success(res) {
          var stats = res.study.stats;
          var data = [];
          for (var i = 0; i < 12; i++) {
            data.push(stats[i] || 0);
          }
          that.set('totalUsers', res.study.total);
          var myChart = new _auto.default(ctx, {
            type: 'bar',
            data: {
              labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
              datasets: [{
                label: '# of Users',
                data: data,
                backgroundColor: ['rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)'],
                borderColor: ['#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90'],
                borderWidth: 1
              }]
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }
          });
          that.set('userChart', myChart);
        }
      });
      // myChart.destroy();
    },
    drawProgressChart: function drawProgressChart() {
      var ctx = document.getElementById('study-progress');
      var myChart = new _auto.default(ctx, {
        type: 'line',
        data: {
          labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: 'Avg Progress',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)', 'rgba(#344D90, 0.2)'],
            borderColor: ['#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90', '#344D90'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
      // myChart.destroy();
      this.set('progressChart', myChart);
    }
  });
});