define("momentumhub/templates/onboarding-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BhuIiOog",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"OnboardingRedirect\"],null]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/onboarding-redirect.hbs"
  });
});