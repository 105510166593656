define("momentumhub/helpers/custom-widgets/quiz", ["exports", "survey-knockout", "survey-core"], function (_exports, Survey, SurveyCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var Quiz = {
    name: "quiz",
    title: "Quiz",
    iconName: "",
    widgetIsLoaded: function widgetIsLoaded() {
      return true;
    },
    isFit: function isFit(question) {
      return question.getType() === 'quiz';
    },
    activatedByChanged: function activatedByChanged(activatedBy) {
      Survey.JsonObject.metaData.addClass("quiz", [], null, "text");
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      Survey.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      Survey.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      Survey.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
      SurveyCore.JsonObject.metaData.addClass("quiz", [], null, "text");
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "choices:itemvalues",
        category: "choices"
      });
      SurveyCore.JsonObject.metaData.addProperty('quiz', {
        name: 'showRightOrWrongBox:boolean',
        category: 'Right or Wrong Box'
      });
      SurveyCore.JsonObject.metaData.addProperty("quiz", {
        name: "correctAnswer:string",
        category: "Correct Answer"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "choices:itemvalues"
      });
      SurveyCore.JsonObject.metaData.addProperty("page", {
        name: "correctAnswer:string"
      });
    },
    isDefaultRender: false,
    htmlTemplate: "<div class=\"quiz-question\"><div class=\"quiz-choices\"></div><div class=\"correct-answer answer-response\">&#10004; Correct Answer</div><div class=\"wrong-answer answer-response\">\n&#10060; Wrong Answer</div></div>",
    afterRender: function afterRender(question, el) {
      var createCustomSurvey = function createCustomSurvey() {
        var wrapperElement = el.closest('.sv-row');
        if (wrapperElement) wrapperElement.classList.add('quiz-wrapper');
        var choices = question.choices;
        var showRightOrWrongBox = question.showRightOrWrongBox;
        var querySelector = el.querySelector('.quiz-choices');
        var choicesHtml = '';
        choices.map(function (choice) {
          var data = choice.getData();
          var isImage = data.text && data.text.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null;
          var divContent = data.text;
          var customClass = '';
          if (isImage) {
            divContent = '<img src="' + data.text + '" data-value="' + data.value + '">';
            customClass = 'image-choice';
          }
          choicesHtml += '<div class="quiz-choice ' + customClass + '" data-value="' + data.value + '">' + divContent + '</div>';
        });
        querySelector.innerHTML = choicesHtml;
        var choiceElements = el.querySelectorAll('.quiz-choice');
        choiceElements.forEach(function (choice) {
          choice.addEventListener('click', function (e) {
            var selected = e.target;
            var parentElement = selected.closest('.quiz-choices');
            parentElement.classList.add('answer-selected');
            var _iterator = _createForOfIteratorHelper(parentElement.getElementsByClassName('quiz-choice')),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var element = _step.value;
                element.classList.remove('selected-answer');
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            selected.closest('.quiz-choice').classList.add('selected-answer');
            var value = selected.getAttribute('data-value');
            if (showRightOrWrongBox) {
              wrapperElement.classList.remove('correct-response');
              wrapperElement.classList.remove('wrong-response');
              if (value === question.correctAnswer) {
                wrapperElement.classList.add('correct-response');
              } else {
                wrapperElement.classList.add('wrong-response');
              }
            }
            question.setNewValue(value);
          });
        });
      };
      question.onItemValuePropertyChanged.add(createCustomSurvey);
      question.registerFunctionOnPropertyValueChanged("choices", createCustomSurvey);
      createCustomSurvey();
    },
    willUnmount: function willUnmount(question, el) {},
    pdfQuestionType: "text"
  };
  var _default = _exports.default = Quiz;
});