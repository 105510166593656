define("momentumhub/routes/study-builder", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/mixins/non-participant-mixin"], function (_exports, _authenticatedRouteMixin, _nonParticipantMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _nonParticipantMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(param) {
      var self = this;
      return Ember.RSVP.hash({
        study: this.store.findRecord('study', param.id)
      }).catch(function (error) {
        var errors = error.errors;
        if (errors[0]) {
          var status = errors[0].status;
          if (status == 404) {
            self.controllerFor("study-builder").set('studyavailable', false);
          }
        }
      });
    },
    afterModel: function afterModel(model) {
      this.controllerFor('study-builder').set('keepVariablesInStudy', model.study.keep_variables_from_previous_study);
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      willTransition: function willTransition(transition) {
        this.controllerFor("study-builder").set('routingTo', transition.to.name);
        this.controllerFor("study-builder").ifNotSaved(transition);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var studyModel = model.study;
      if (studyModel != undefined) {
        var studyName = studyModel.get('study_name');
        var structure_json = studyModel.get('structure_json');
        var variables_to_track = studyModel.get('variables_to_track');
        console.log('route study builder varstotrack', variables_to_track);
        if (studyName) {
          this.controllerFor("study-builder").set('studyavailable', true);
          this.controllerFor("study-builder").createArmsAndPhases();
          this.controllerFor("study-builder").set("study_name", studyName.replace(/ /g, ""));
          this.controllerFor("study-builder").set("phaseId", "");
          this.controllerFor("study-builder").set("previousPhaseId", "");
          this.controllerFor("study-builder").set("selectedArmPhase", {});
          this.controllerFor("study-builder").set("totalArms", []);
          this.controllerFor("study-builder").set("treeSelected", true);
          this.controllerFor("study-builder").set("variableSelected", false);
          this.controllerFor("study-builder").set("isTreeEditing", false);
          this.controllerFor("study-builder").send('getRules');
          this.controllerFor("study-builder").send('getTimeBasedRules');
          this.controllerFor("study-builder").send('getModules');
          this.controllerFor("study-builder").send('getSurveys');
          // this.controllerFor("study-builder").setStudyVariables(model);
          this.controllerFor("study-builder").send('getGlobalVariables');
          this.controllerFor("study-builder").set('newRule', false);
          this.controllerFor("study-builder").set('ruleSelected', true);
          this.controllerFor("study-builder").set('moduleSelected', false);
          this.controllerFor("study-builder").set('surveySelected', false);
          this.controllerFor("study-builder").set('alertmessagechage', false);
          this.controllerFor("study-builder").set('partId', "");
          this.controllerFor("study-builder").set('partName', "");
          $('.media-choices').css({
            "color": 'black'
          });
          $('.shadow').animate({
            left: '0px'
          }, 300);
          $('.tree').css({
            "color": 'white'
          });
        }
      }
    }
  });
});