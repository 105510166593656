define("momentumhub/routes/usermanagement/studies", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var user_email = this.session.get('data.user_email');
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          email__iexact: user_email
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.setPermissions();
      this.controllerFor("usermanagement.studies").getStudies();
      var userModel = model.users;
      if (userModel.get('firstObject')) {
        var user = userModel.get('firstObject');
        this.controllerFor("usermanagement.studies").set('currentUser', user);
      }
    },
    setPermissions: function setPermissions() {
      var user_type = this.controllerFor("usermanagement.studies").get('user_type');
      if (user_type == "PA" || user_type == "SA") {
        this.controllerFor("usermanagement.studies").set('researcherAccess', false);
        this.controllerFor("usermanagement.studies").set('participantSelected', false);
      } else {
        this.controllerFor("usermanagement.studies").set('researcherAccess', true);
        this.controllerFor("usermanagement.studies").set('participantSelected', true);
      }
    }
  });
});