define("momentumhub/components/addsiteadmin-tostudy", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "ngZhEbNI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "momentumhub/components/addsiteadmin-tostudy.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    toast: Ember.inject.service(),
    search: "",
    delayTimer: "",
    searchUsers: [],
    siteSelected: [],
    page: 1,
    showAllUsers: false,
    previousPage: "",
    nextPrevious: "",
    totalPages: 1,
    participantSelected: false,
    multipleUsers: [],
    addAllUsers: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      close: function close() {
        (0, _jquery.default)('.hover_bkgr_fricc-researchersgroup-comp').hide();
        this.set('showAllUsers', false);
        this.set('searchUsers', []);
        this.set('page', 1);
        this.set('multipleUsers', []);
        this.set('addAllUsers', false);
      },
      searchUser: function searchUser() {
        var that = this;
        var search = this.get('search');
        clearTimeout(this.delayTimer);
        that.delayTimer = setTimeout(function () {
          if (search) {
            that.getUsers();
            that.set('showAllUsers', false);
            that.set('page', 1);
          } else {
            that.set('searchUsers', []);
          }
        }, 1000);
      },
      showAll: function showAll(type) {
        this.set('search', "");
        if (type == "show") {
          this.set('showAllUsers', true);
          this.getUsers();
        } else {
          this.set('showAllUsers', false);
          this.set('searchUsers', []);
          this.set('page', 1);
          this.set('multipleUsers', []);
          this.set('addAllUsers', false);
        }
      },
      previousButton: function previousButton() {
        var page = this.page;
        page = +page - 1;
        this.set("page", page);
        this.getUsers();
        if (this.multipleUsers.length > 0) {
          this.send('removeAllAndNext');
        }
      },
      mextButton: function mextButton() {
        var page = this.page;
        page = +page + 1;
        this.set("page", page);
        this.getUsers();
        if (this.multipleUsers.length > 0) {
          this.send('removeAllAndNext');
        }
      },
      addResearcherToGroup: function addResearcherToGroup(selectedUser) {
        var that = this;
        var r = confirm("Are you sure you want to add " + selectedUser.first_name + " " + selectedUser.last_name + " to this site ?");
        if (r) {
          var site = this.siteSelected;
          var selectedUserId = selectedUser.id;
          var accessToken = this.get('session.data.authenticated.access_token');
          var data_to_api = {
            "site_admin": selectedUserId,
            "site": site.id,
            "active": true
          };
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/siteadminmomentumhubsites',
            type: 'POST',
            data: data_to_api,
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function success(res) {
              that.newAlert("Site admin added to the site.", "Site Admin Added", "Success");
              that.sendAction('refreshCurrentRoute');
              if (that.showAllUsers) {} else {
                that.set('searchUsers', []);
                that.set('showAllUsers', false);
                that.set('search', "");
              }
            },
            error: function error(err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Add User", "Failure");
            }
          });
        }
      },
      deleteSiteAdmin: function deleteSiteAdmin(id) {
        var accessToken = this.get('session.data.authenticated.access_token');
        var that = this;
        var r = confirm("Are you sure you want to delete this site admin from site");
        if (r) {
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST + '/siteadminmomentumhubsites/' + id,
            type: 'DELETE',
            headers: {
              "Authorization": "Bearer " + accessToken
            },
            success: function success(res) {
              that.newAlert("Site Admin deleted successfully.", "Delete site admin", "Success");
              that.sendAction('refreshCurrentRoute');
            },
            error: function error(err, xH) {
              var str = err.responseText;
              str = str.split(':["').pop();
              str = str.split('"]')[0];
              that.newAlert(str, "Add User", "Failure");
            }
          });
        }
      }
    },
    getUsers: function getUsers() {
      var that = this;
      var search = this.get('search');
      this.store.query('user', {
        all_users: true,
        user_type: "PA",
        search: search,
        page: that.page
      }).then(function (searchUsers) {
        that.set('searchUsers', searchUsers);
        that.set('previousPage', searchUsers.meta.previous);
        that.set('nextPrevious', searchUsers.meta.next);
        if (searchUsers.meta.count) {
          var totalPages = searchUsers.meta.count / 15;
          if (totalPages % 1 != 0) {
            that.set('totalPages', parseInt(totalPages) + 1);
          } else {
            that.set('totalPages', parseInt(totalPages));
          }
        }
        if (that.showAllUsers) {
          that.checkIfUserIsAdded();
        }
      });
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  }));
});