define("momentumhub/components/participant-leftnavbar", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    expandMenu: true,
    totalUnread: 0,
    isTeenager: false,
    isGuardian: false,
    infoMenuModule: Ember.computed('isTeenager', 'viewingAsGuardian', function () {
      if (this.get('viewingAsGuardian')) {
        return _environment.default.APP.LINK_MENUBAR_INFORMATION_GUARDIAN;
      } else if (this.get('isTeenager')) {
        return _environment.default.APP.LINK_MENUBAR_INFORMATION_TEEN;
      } else {
        return _environment.default.APP.LINK_MENUBAR_INFORMATION_CHILD;
      }
    }),
    guardianHomeModule: Ember.computed('isGuardian', function () {
      return _environment.default.APP.LINK_MENUBAR_HOME_GUARDIAN;
    }),
    helpButtonModule: Ember.computed('isTeenager', function () {
      if (this.get('isTeenager')) {
        return _environment.default.APP.LINK_HELP_TEEN;
      } else {
        return _environment.default.APP.LINK_HELP_CHILD;
      }
    }),
    viewingAsGuardian: Ember.computed('session.data.viewingUserType', function () {
      var userType = this.get('session.data.viewingUserType') || this.get('session.data.user_type');
      return userType === 'PR';
    }),
    init: function init() {
      var that = this;
      this._super.apply(this, arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
      var user_email = this.session.get('data.user_email');
      this.store.query('user', {
        email__iexact: user_email
      }).then(function (users) {
        var user = users.get('firstObject');
        var user_type = user.get('user_type');
        that.session.set('data.user_type', user_type);
        if (user_type == 'PR') {
          that.set('isGuardian', true);
        } else {
          that.set('isGuardian', false);
        }
      });
    },
    actions: {
      closeMenu: function closeMenu() {
        (0, _jquery.default)('.participant-left-navbar').hide();
      },
      logout: function logout() {
        (0, _jquery.default)('#isLogoutClicked').click();
        this.get('session').invalidate();
        this.session.set('data.user_email', "");
        this.session.set('data.viewingEmail', "");
        this.session.set('data.viewingName', "");
        this.session.set('data.viewingId', "");
        this.session.set('data.viewingAge', "");
        this.session.set('data.viewingDark_theme', "");
        this.session.set('data.user_type', '');
        this.session.set('data.userId', '');
        localStorage.removeItem('ldt');
        window.location.href = _environment.default.APP.API_HOST_INITIAL + '/account/logout/';
      },
      collapseMenu: function collapseMenu() {
        var that = this;
        if (this.expandMenu) {
          this.set('expandMenu', false);
          (0, _jquery.default)("#showfullmenu").hide();
          (0, _jquery.default)("#showcollapsemenu").show();
          (0, _jquery.default)('.participant-left-navbar').animate({
            width: '120px'
          }, 500);
          (0, _jquery.default)('.right-participant-view').animate({
            width: '100vw'
          }, 500);
        } else {
          (0, _jquery.default)('.participant-left-navbar').animate({
            width: '270px'
          }, 500);
          (0, _jquery.default)('.right-participant-view').animate({
            width: 'calc(100vw - 330px)'
          }, 500);
          setTimeout(function () {
            that.set('expandMenu', true);
            (0, _jquery.default)("#showfullmenu").show();
            (0, _jquery.default)("#showcollapsemenu").hide();
          }, 300);
        }
      },
      closeMenuOnButtonSelection: function closeMenuOnButtonSelection() {
        var x = window.matchMedia("(max-width: 1300px)");
        if (x.matches) {
          (0, _jquery.default)('.participant-left-navbar').hide();
        }
      }
    }
  });
});