define("momentumhub/models/mountain-step", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    step: _emberData.default.attr(),
    reward: _emberData.default.attr(),
    worry_level: _emberData.default.attr('number'),
    is_complete: _emberData.default.attr('boolean'),
    mountain: _emberData.default.belongsTo('mountain')
  });
});