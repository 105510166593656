define("momentumhub/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cz/2O7CO",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Index\"],null]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"user_type\"],[\"Participant\"]]]],[2,\"\\n\"],[1,[34,2]],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"landing-page\",\"footer-component\",\"-outlet\",\"component\"]}",
    "moduleName": "momentumhub/templates/index.hbs"
  });
});