define("momentumhub/components/module-assets", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    uploadFile: false,
    //Action 
    actions: {
      //Close assets component
      close: function close() {
        (0, _jquery.default)('.close').hide();
        (0, _jquery.default)('.inner-component').hide();
        (0, _jquery.default)('.assets-component').css({
          padding: '0px'
        }, 300);
        (0, _jquery.default)('.assets-component').animate({
          width: '0px'
        }, 300);
        this.set('uploadFile', false);
      },
      //Choose media 
      chooseMedia: function chooseMedia(mediatype) {
        if (mediatype === "modules") {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '0px'
          }, 300);
          (0, _jquery.default)('.photos').css({
            "color": 'white'
          });
        } else {
          (0, _jquery.default)('.media-choices').css({
            "color": 'black'
          });
          (0, _jquery.default)('.shadow').animate({
            left: '50%'
          }, 300);
          (0, _jquery.default)('.videos').css({
            "color": 'white'
          });
        }
      },
      // Upload file clicked
      uploadFileClicked: function uploadFileClicked() {
        var uploadFile = this.get('uploadFile');
        if (uploadFile) {
          this.set('uploadFile', false);
        } else {
          this.set('uploadFile', true);
        }
      }
    }
  });
});