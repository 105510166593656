define("momentumhub/models/mood", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    mood_type: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    created_at: _emberData.default.attr()
  });
});