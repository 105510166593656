define("momentumhub/routes/climbing-plans/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment", "survey-knockout", "momentumhub/helpers/custom-widgets", "momentumhub/mixins/guardian-session-mixin"], function (_exports, _authenticatedRouteMixin, _environment, Survey, _customWidgets, _guardianSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _guardianSessionMixin.default, {
    session: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model(param) {
      var viewingId = this.session.get('data.viewingId');
      if (!viewingId || viewingId.length === 0) viewingId = this.session.get('data.userId');
      return Ember.RSVP.hash({
        survey: this.store.query('survey', {
          identifier: _environment.default.APP.DIARY_CLIMBINGPLANS_FORMID
        }),
        activity: this.store.query('activity', {
          identifier: _environment.default.APP.DIARY_CLIMBINGPLANS_FORMID,
          user: viewingId
        }),
        mountain: this.store.query('mountain', {
          user: viewingId
        })
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      showTipsAndTricksModule: function showTipsAndTricksModule() {
        var moduleId = _environment.default.APP.CLIMBING_PLANS_TIPS_AND_TRICKS_TEEN;
        var participantAge = window.localStorage.getItem("participantAge");
        if (participantAge <= 12) {
          moduleId = _environment.default.APP.CLIMBING_PLANS_TIPS_AND_TRICKS_CHILD;
        }
        this.transitionTo('modulePreview', moduleId);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});