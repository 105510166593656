define("momentumhub/models/siteadminmomentumhubsite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    site_admin: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('mommentumhubsite'),
    active: _emberData.default.attr('boolean'),
    first_name: _emberData.default.attr(''),
    last_name: _emberData.default.attr(''),
    email: _emberData.default.attr('')
  });
});