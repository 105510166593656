define("momentumhub/components/login-component", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    resetPassword: false,
    emailSent: false,
    twofaAuthentication: false,
    isAGuardian: false,
    delayTimer: "",
    userType: "",
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var signedInUser = localStorage.getItem("signedInUser");
      if (signedInUser) {
        this.set('indentification', signedInUser);
        this.send('checkUserAndGuardian');
      }
    },
    //Action
    actions: {
      /* Close assets component */
      close: function close() {
        (0, _jquery.default)('.hover_bkgr_fricc').hide();
        this.set('indentification', "");
        this.set('password', "");
        this.set('resetPassword', false);
        this.set('emailSent', false);
        this.set('twofaAuthentication', false);
        this.set('isAGuardian', false);
      },
      resetPasswordClicked: function resetPasswordClicked() {
        if (this.resetPassword) {
          this.set('resetPassword', false);
          this.set('emailSent', false);
        } else {
          this.set('resetPassword', true);
        }
      },
      sendEmail: function sendEmail() {
        var that = this;
        var email = this.get('indentification');
        if (this.isAGuardian) {
          var e = document.getElementById("loginAs");
          var user_type = e.options[e.selectedIndex].value;
          if (user_type == "2") {
            email = that.getGuardianEmail(email);
          }
        }
        if (this.validateEmail(email)) {
          var reset_email_data_to_api = {
            "email": email
          };
          return that.get('ajax').request(_environment.default.APP.API_HOST_INITIAL + '/rest-auth/password/reset/', {
            method: 'POST',
            data: reset_email_data_to_api
          }).then(function (response) {
            that.set('emailSent', true);
          });
        }
      },
      //************ 1. Check user first *************** */
      checkStaff: function checkStaff() {
        var self = this;
        var email = self.get('indentification').toLowerCase();
        var data = {
          "email": email
        };
        console.log(_environment.default.APP.API_HOST_INITIAL);
        this.get('ajax').request(_environment.default.APP.API_HOST_INITIAL + '/user/check-user-type', {
          method: 'POST',
          data: data
        }).then(function (response) {
          var user = response.user.type;
          var disable_2fa = response.user.disable_2fa;
          self.set('userType', user);
          if (user) {
            if (user === "RS" || user === "PA" || user === "SA" || user === "PL" || user === "ST" || user === 'CL') {
              if (disable_2fa) {
                self.session.set('data.isParticipant', false);
                self.send('authenticationAfterTwoFactor', false);
              } else {
                self.session.set('data.isParticipant', false);
                self.send('authenticate1');
              }
            }
            if (user === "PR") {
              self.session.set('data.isParticipant', true);
              self.send('authenticationAfterTwoFactor', true);
            }
            if (user === "PT") {
              self.send('participantLogin');
            }
          } else {
            alert('Incorrect email; please try again.');
          }
        });
      },
      checkGuardianSelect: function checkGuardianSelect() {
        var e = document.getElementById("loginAs");
        var user_type = e.options[e.selectedIndex].value;
        if (user_type == "2") {
          this.set('isAGuardian', true);
        } else {
          this.set('isAGuardian', false);
        }
      },
      checkUserAndGuardian: function checkUserAndGuardian() {
        var self = this;
        this.set('isAGuardian', false);
        var email = self.get('indentification');
        clearTimeout(this.delayTimer);
        self.delayTimer = setTimeout(function () {
          var data = {
            "email": email.toLowerCase()
          };
          self.get('ajax').request(_environment.default.APP.API_HOST_INITIAL + '/user/check-user-type', {
            method: 'POST',
            data: data
          }).then(function (response) {
            var user = response.user.type;
            if (user) {
              self.set('userType', user);
              if (user === "PT" || user === "PR" || user === "CL") {
                self.session.set('data.isParticipant', true);
                var guardianEmail = self.getGuardianEmail(email);
                var data1 = {
                  "email": guardianEmail
                };
                self.get('ajax').request(_environment.default.APP.API_HOST_INITIAL + '/user/check-guardian-email', {
                  method: 'POST',
                  data: data1
                }).then(function (response) {
                  var is_valid = response.user.is_valid;
                  if (is_valid) {
                    self.set('isAGuardian', is_valid);
                  }
                });
              }
            }
          });
        }, 1000);
      },
      authenticate1: function authenticate1() {
        var self = this;
        var identification = this.get('indentification');
        var password = this.get('password');
        var data_to_api = {
          "grant_type": "password",
          "username": identification.toLowerCase(),
          "password": password,
          "client_id": _environment.default.APP.client_id
          // "client_secret": ENV.APP.client_secret
        };
        _jquery.default.ajax({
          url: _environment.default.APP.API_HOST_TOKEN,
          type: 'POST',
          data: data_to_api,
          success: function success(res) {
            self.set('twofaAuthentication', true);
          },
          error: function error(err, xH) {
            var str = err.responseText;
            (0, _jquery.default)('.incorrectuspassalert').show();
            (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
            self.set('authentication_code', "");
            setTimeout(function () {
              (0, _jquery.default)('.incorrectuspassalert').hide();
            }, 4000);
          }
        });
      },
      authenticate: function authenticate() {
        var email = this.get('indentification');
        var that = this;
        var twofactorinput = this.get('authentication_code');
        if (twofactorinput) {
          var data_to_api = {
            "email": email.toLowerCase(),
            "otp": twofactorinput
          };
          _jquery.default.ajax({
            url: _environment.default.APP.API_HOST_INITIAL + '/user/2fa',
            type: 'POST',
            data: data_to_api,
            success: function success(res) {
              that.set('authentication_code', "");
              that.send('authenticationAfterTwoFactor', false);
              (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').show();
              (0, _jquery.default)('.hover_bkgr_fricc').hide();
            },
            error: function error(err, xH) {
              (0, _jquery.default)('.incorrectuspassalert').show();
              that.set('authentication_code', "");
              document.getElementById("authentication_code").focus();
            }
          });
        }
      },
      /* Authenticate the user */authenticationAfterTwoFactor: function authenticationAfterTwoFactor(isGuardian) {
        var self = this;
        this.session.set('data.viewingName', "");
        this.session.set('data.viewingId', "");
        this.session.set('data.viewingAge', "");
        this.session.set('data.viewingDark_theme', "");
        this.session.set('data.user_email', "");
        this.session.set('data.loginHedgdoc', "");
        var _self$getProperties = self.getProperties('indentification', 'password'),
          indentification = _self$getProperties.indentification,
          password = _self$getProperties.password;
        indentification = indentification.toLowerCase();
        if (indentification && password) {
          if (this.isAGuardian) {
            var e = document.getElementById("loginAs");
            var user_type = e.options[e.selectedIndex].value;
            if (user_type == "2") {
              indentification = self.getGuardianEmail(indentification);
            }
          }
          self.session.set('data.user_email', indentification);
          self.get('session').authenticate('authenticator:oauth2', indentification, password).then(function (result) {
            var token = self.get('session.data.authenticated.access_token');
            if (token) {
              self.session.set('data.loginHedgdoc', "true");
              if (self.isAGuardian) {
                var message = "";
                if (self.userType === "PT") {
                  message = "Successfully logged in as Participant";
                } else {
                  message = "Successfully logged in as Guardian.";
                }
                self.newAlert(message, "Login", "Success");
              }
              if (self.userType === 'CL') {
                self.get('router').transitionTo('dashboard');
              } else if (isGuardian) {
                self.store.query('user', {
                  email: indentification
                }).then(function (userModel) {
                  var user = userModel.get('firstObject');
                  self.session.set('data.userId', user.id);
                  self.get('router').transitionTo('modulePreview', _environment.default.APP.LINK_MENUBAR_INFORMATION_GUARDIAN);
                });
              } else {
                setTimeout(function () {
                  self.authenticateApi(token);
                }, 1000);
              }
              (0, _jquery.default)('.incorrectuspassalert').hide();
              (0, _jquery.default)('.loading_line').animate({
                width: '100%'
              }, 2000);
              (0, _jquery.default)('.successful-login').show();
              (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
              self.saveusernameInLocalstorage(indentification);
              self.set('indentification', "");
              self.set('password', "");
              self.set('twofaAuthentication', false);
              setTimeout(function () {
                (0, _jquery.default)('.loading_line').animate({
                  width: '0%'
                }, 2000);
                (0, _jquery.default)('.successful-login').hide();
                (0, _jquery.default)('.hover_bkgr_fricc').hide();
              }, 2010);
            }
          }, function (reason) {
            self.set('errorMessage', reason.error || reason);
            (0, _jquery.default)('.incorrectuspassalert').show();
            (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
            setTimeout(function () {
              (0, _jquery.default)('.incorrectuspassalert').hide();
            }, 4000);
          });
        } else {
          (0, _jquery.default)('.nouspassalert').show();
          setTimeout(function () {
            (0, _jquery.default)('.nouspassalert').hide();
          }, 4000);
        }
      },
      participantLogin: function participantLogin() {
        var self = this;
        this.session.set('data.viewingName', "");
        this.session.set('data.viewingId', "");
        this.session.set('data.viewingAge', "");
        this.session.set('data.viewingDark_theme', "");
        this.session.set('data.user_email', "");
        this.session.set('data.loginHedgdoc', "");
        var _self$getProperties2 = self.getProperties('indentification', 'password'),
          indentification = _self$getProperties2.indentification,
          password = _self$getProperties2.password;
        if (indentification && password) {
          var user_type = "";
          if (this.isAGuardian) {
            var e = document.getElementById("loginAs");
            user_type = e.options[e.selectedIndex].value;
          }
          if (user_type == "2") {
            self.send('authenticationAfterTwoFactor', true);
          } else {
            self.session.set('data.user_email', indentification);
            self.get('session').authenticate('authenticator:oauth2', indentification, password).then(function (result) {
              var token = self.get('session.data.authenticated.access_token');
              if (token) {
                // Check participant user onboarding status
                _jquery.default.ajax({
                  url: _environment.default.APP.API_HOST + '/users?email__iexact=' + indentification,
                  type: 'GET',
                  headers: {
                    "Authorization": "Bearer " + token
                  },
                  success: function success(res) {
                    var users = res.user;
                    if (users[0]) {
                      var has_completed_onboarding = users[0].has_completed_onboarding;

                      // Completed on boarding press
                      if (has_completed_onboarding) {
                        self.session.set('data.loginHedgdoc', "true");
                        if (self.isAGuardian) {
                          var message = "";
                          if (self.userType === "PT") {
                            message = "Successfully logged in as Participant";
                          } else {
                            message = "Successfully logged in as Guardian";
                          }
                          self.newAlert(message, "Login", "Success");
                        }
                        self.get('router').transitionTo('dashboard');
                        (0, _jquery.default)('.incorrectuspassalert').hide();
                        (0, _jquery.default)('.loading_line').animate({
                          width: '100%'
                        }, 2000);
                        (0, _jquery.default)('.successful-login').show();
                        self.saveusernameInLocalstorage(indentification);
                        self.set('indentification', "");
                        self.set('password', "");
                        self.set('twofaAuthentication', false);
                        setTimeout(function () {
                          (0, _jquery.default)('.loading_line').animate({
                            width: '0%'
                          }, 2000);
                          (0, _jquery.default)('.successful-login').hide();
                          (0, _jquery.default)('.hover_bkgr_fricc').hide();
                        }, 2010);
                      }

                      // Haven't completed onboarding study
                      else {
                        var age = users[0].age;
                        var userId = users[0].id;
                        self.send('close');
                        (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').show();
                        self.getStudy(userId, age);
                        self.saveusernameInLocalstorage(indentification);
                      }
                    }
                  },
                  error: function error(err, xH) {
                    var str = err.responseText;
                    str = str.split(':["').pop();
                    str = str.split('"]')[0];
                  }
                });
              }
            }, function (reason) {
              self.set('errorMessage', reason.error || reason);
              (0, _jquery.default)('.incorrectuspassalert').show();
              (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
              setTimeout(function () {
                (0, _jquery.default)('.incorrectuspassalert').hide();
              }, 4000);
            });
          }
        } else {
          (0, _jquery.default)('.nouspassalert').show();
          setTimeout(function () {
            (0, _jquery.default)('.nouspassalert').hide();
          }, 4000);
        }
      }
    },
    authenticateApi: function authenticateApi(access_token) {
      window.location.href = _environment.default.APP.API_HOST_INITIAL + '/api/login?access_token=' + access_token;
    },
    saveusernameInLocalstorage: function saveusernameInLocalstorage(user_email) {
      var loginDate = Date();
      user_email = user_email.replace(/\+.*@/g, '@');
      localStorage.setItem("signedInUser", user_email);
      localStorage.setItem("ldt", loginDate);
    },
    //Validate email
    validateEmail: function validateEmail(email) {
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        return true;
      }
      alert("You have entered an invalid email address!");
      return false;
    },
    /***************************** 3. Get study and arm to create a userstudy ********************* */
    getStudy: function getStudy(userId, age) {
      var that = this;
      var phaseArray = {};
      //1. Get Study
      that.store.query('study', {
        on_boarding_study: true,
        age: age
      }).then(function (studyModel) {
        var study = studyModel.get('firstObject');
        var studyId = study.get('id');
        var number_of_arms = study.get('number_of_arms');

        //2. Get initial study part
        var initial_study_part = study.get('initial_study_part');
        if (initial_study_part) {
          that.store.findRecord('part', initial_study_part).then(function (initial_study) {
            var initial_study_json = initial_study.get('part_result_json');
            phaseArray[initial_study_part] = initial_study_json;
            if (number_of_arms == 0) {
              that.createStudyUser(userId, studyId, phaseArray);
            }
          });
        }
        if (number_of_arms != 0) {
          //3. Get Arm
          that.store.query('arm', {
            study: study.get('id')
          }).then(function (arm) {
            //4.  Get Phases
            var firstArm = arm.get('firstObject');
            that.store.query('phase', {
              arm: firstArm.get('id')
            }).then(function (phases) {
              if (phases.length > 0) {
                //5. Get Parts
                var phaseLength = phases.length;
                phases.forEach(function (phase, index) {
                  var lastCount = index + 1;
                  that.store.query('part', {
                    phase: phase.get('id')
                  }).then(function (part) {
                    var firstPart = part.get('firstObject');
                    var partId = firstPart.get('id');
                    var part_result_json = firstPart.get('part_result_json');
                    if (part_result_json) {
                      phaseArray[partId] = part_result_json;
                    }
                    if (phaseLength == lastCount) {
                      //6. Create study user
                      console.log(phaseArray);
                      that.createStudyUser(userId, studyId, phaseArray);
                    }
                  });
                });
              } else {
                that.createStudyUser(userId, studyId, phaseArray);
              }
            });
          });
        }
      });
    },
    /**************************** 4. Create study user ************************** */
    createStudyUser: function createStudyUser(userId, studyId, user_study_part_json) {
      var that = this;
      var self = this;
      var accessToken = this.get('session.data.authenticated.access_token');
      var data_to_api = {
        "user": userId,
        "study": studyId,
        "user_study_part_json": JSON.stringify(user_study_part_json),
        "is_onboarding": true
      };
      _jquery.default.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers',
        type: 'POST',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        data: data_to_api,
        success: function success(res) {
          var studyUserId = res.studyuser.id;
          setTimeout(function () {
            that.get('router').transitionTo('studyview', studyUserId, "na");
            (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
          }, 3000);
        },
        error: function error(err, xH) {
          var errorMessage = err.responseText;
          if (errorMessage.includes('The fields study, user must make a unique set')) {
            self.session.set('data.loginHedgdoc', "true");
            if (self.isAGuardian) {
              var message = "";
              if (self.userType === "PT") {
                message = "Successfully logged in as Participant";
              } else {
                message = "Successfully logged in as Guardian";
              }
              self.newAlert(message, "Login", "Success");
            }
            self.get('router').transitionTo('dashboard');
            (0, _jquery.default)('.incorrectuspassalert').hide();
            (0, _jquery.default)('.loading_line').animate({
              width: '100%'
            }, 2000);
            (0, _jquery.default)('.successful-login').show();
            self.set('indentification', "");
            self.set('password', "");
            self.set('twofaAuthentication', false);
            setTimeout(function () {
              (0, _jquery.default)('.loading_line').animate({
                width: '0%'
              }, 2000);
              (0, _jquery.default)('.successful-login').hide();
              (0, _jquery.default)('.hover_bkgr_fricc').hide();
            }, 2010);
          }
          (0, _jquery.default)('.hover_bkgr_fricccc_spinner1').hide();
        }
      });
    },
    getGuardianEmail: function getGuardianEmail(email) {
      var email_start = email.substr(0, email.indexOf('@'));
      var email_end = email.split('@')[1];
      return email_start + "+guardian@" + email_end;
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
});