define("momentumhub/models/mountain", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    steps: _emberData.default.hasMany('mountain-step', {
      embedded: 'always'
    })
  });
});