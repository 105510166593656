define("momentumhub/controllers/feelingstracker", ["exports", "momentumhub/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    hedgeDocUrl: "",
    isTeenager: false,
    isChild: false,
    init: function init() {
      this._super.apply(this, arguments);
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
        this.set('isChild', true);
      } else {
        this.set('isTeenager', true);
        this.set('isChild', false);
      }
    },
    actions: {
      openMoreInfoComponent: function openMoreInfoComponent(type) {
        var participantAge = localStorage.getItem('participantAge'); //this needs to be defined here too, even though it is above
        var hedgeDocUrl_anxiety = "";
        var hedgeDocUrl_mood = "";
        var child_anx_moduleID = _environment.default.APP.FEELING_TRACKER_ANXIETY_MODULE_CHILD;
        var teen_anx_moduleID = _environment.default.APP.FEELING_TRACKER_ANXIETY_MODULE_TEEN;
        var child_mood_moduleID = _environment.default.APP.FEELING_TRACKER_MOOD_MODULE_CHILD;
        var teen_mood_moduleID = _environment.default.APP.FEELING_TRACKER_MOOD_MODULE_TEEN;
        if (type == "anxiety") {
          if (participantAge <= 12) {
            hedgeDocUrl_anxiety = _environment.default.APP.HEDGE_DOC + "/p/" + child_anx_moduleID;
          } else if (participantAge > 12) {
            hedgeDocUrl_anxiety = _environment.default.APP.HEDGE_DOC + "/p/" + teen_anx_moduleID;
          }
          this.set('hedgeDocUrl', hedgeDocUrl_anxiety);
        } else if (type == "mood") {
          if (participantAge <= 12) {
            hedgeDocUrl_mood = _environment.default.APP.HEDGE_DOC + "/p/" + child_mood_moduleID;
          } else if (participantAge > 12) {
            hedgeDocUrl_mood = _environment.default.APP.HEDGE_DOC + "/p/" + teen_mood_moduleID;
          }
          this.set('hedgeDocUrl', hedgeDocUrl_mood);
        }
        $('.moreinfoComponent').show();
      },
      closeMoreInfoComponent: function closeMoreInfoComponent() {
        $('.moreinfoComponent').hide();
      },
      routeToAssessmentResults: function routeToAssessmentResults() {
        this.transitionToRoute('assesmentResults');
      }
    }
  });
});