define("momentumhub/templates/power-thinking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6gBTYHQR",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[1,[30,[36,1],[\"Power Thinking\"],null]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"header-section\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"left-side-images\"],[12],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"right-side-text\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n            Our thoughts are VERY powerful! POWER THINKING is like a superpower! Click below to practice different ways to harness your superpower and use POWER THINKING\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"modules\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"model\",\"modules\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"each-module background-module-thumbnail-participant power-thinking-item\"],[16,5,[31,[\"background-image: url(\",[32,1,[\"thumbnail_image\"]],\");\"]]],[4,[38,0],[[32,0],\"previewModule\",[32,1,[\"identifier\"]]],null],[12],[2,\"\\n\"],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"page-title\",\"-outlet\",\"component\",\"-track-array\",\"each\"]}",
    "moduleName": "momentumhub/templates/power-thinking.hbs"
  });
});