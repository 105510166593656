define("momentumhub/controllers/reset-password", ["exports", "jquery", "momentumhub/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    uid: "",
    token: "",
    //Action 
    actions: {
      resetPassword: function resetPassword() {
        var that = this;
        var password = this.get('password');
        var confirm_password = this.get('confirm_password');
        var uid = this.uid;
        var token = this.token;
        if (this.validatePassword(password)) {
          if (password === confirm_password) {
            var reset_email_data_to_api = {
              "new_password1": password,
              "new_password2": confirm_password,
              "uid": uid,
              "token": token
            };
            _jquery.default.ajax({
              url: _environment.default.APP.API_HOST_INITIAL + '/rest-auth/password/reset/confirm/',
              type: 'POST',
              data: reset_email_data_to_api,
              success: function success(res) {
                that.newAlert("Your password resets successfully!!", "Password", "Success");
                that.transitionToRoute('index');
              },
              error: function error(err, xH) {
                var str = err.responseText;
                str = str.split(':["').pop();
                str = str.split('"]')[0];
                that.newAlert(str, "Password", "Failure");
              }
            });
          } else {
            that.newAlert("Password and confirm password doesn't match.", "Password", "Warning");
          }
        }
      }
    },
    validatePassword: function validatePassword(password) {
      var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{9,30}/;
      var pr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{9,30}$/;
      if (re.test(password)) {
        return true;
      }
      this.newAlert("Please use 9 characters, use at least one number, one uppercase letter, one lowercase letter and one special symbol!", "Password", "Warning");
      return false;
    },
    /*  Alert to show success/ failure or warning   */
    newAlert: function newAlert(message, title, type) {
      if (type === "Success") {
        this.toast.success(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else if (type === "Failure") {
        this.toast.error(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      } else {
        this.toast.warning(message, title, {
          "closeButton": false,
          "debug": false,
          "newestOnTop": false,
          "progressBar": false,
          "positionClass": "toast-top-right",
          "preventDuplicates": false,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        });
      }
    }
  });
});