define("momentumhub/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    actor_object_id: _emberData.default.attr(),
    recipient: _emberData.default.attr(),
    verb: _emberData.default.attr(),
    description: _emberData.default.attr(),
    unread: _emberData.default.attr('boolean'),
    timestamp: _emberData.default.attr(),
    actor_content_type: _emberData.default.attr(),
    notification_settings: _emberData.default.attr()
  });
});