define("momentumhub/routes/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "momentumhub/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    model: function model() {
      var viewingId = this.session.get('data.viewingId');
      if (!viewingId || viewingId.length === 0) viewingId = this.session.get('data.userId');
      var email = this.session.get('data.user_email');
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          email__iexact: email
        }),
        rewards: this.store.query('userrewardvar', {
          'user': viewingId
        })
      });
    },
    afterModel: function afterModel(model) {
      var userModel = model.users;
      if (userModel.length) {
        var user = userModel.get('firstObject');
        var user_id = user.get('id');
        var user_type = user.get('user_type');
        var has_completed_onboarding = user.get('has_completed_onboarding');
        var showDashboard = false;
        if (user_type == "PT") {
          if (has_completed_onboarding) {
            this.controllerFor("dashboard").set('showDashboard', true);
          } else {
            this.controllerFor("dashboard").set('showDashboard', false);
            this.checkOnboardingStudy(user_id);
          }
        }
      }
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (this.session.isAuthenticated) {
        this.settingAccessTokenHedgedoc();
        var userModel = model.users;
        if (userModel.length) {
          var user = userModel.get('firstObject');
          var user_type = user.get('user_type');
          var user_id = user.get('id');
          var user_dob = user.get('age');
          this.session.set('data.userId', user_id);
          this.session.set('data.user_type', user_type);
          this.session.set('data.report_password', user.get('report_password'));
          // If Participant
          if (user_type == "PT") {
            var participantAge = user_dob;
            if (participantAge > 12) {
              $('.participant-application-view').addClass('teenager');
              $('.participant-application-view').removeClass('child');
            } else {
              $('.participant-application-view').removeClass('teenager');
              $('.participant-application-view').addClass('child');
            }
            var selected_theme = user.get('dark_theme');
            // this.changeTheme(user_dob, selected_theme);
            localStorage.setItem("participantAge", participantAge);
            localStorage.setItem("selected_theme", selected_theme);
            this.controllerFor("application").set('isParticipant', true);
            this.controllerFor("dashboard").set('isParticipant', true);
            this.session.set('data.isParticipant', true);
            this.session.set('data.isParent', false);
            this.session.set('data.has_completed_onboarding', user.get('has_completed_onboarding'));
          }

          // If Parent
          else if (user_type == "PR") {
            // this.setupParentUi(user_id);
            this.controllerFor("application").set('isParticipant', true);
            this.controllerFor("dashboard").set('isParticipant', true);
            this.session.set('data.isParticipant', true);
            this.session.set('data.isParent', true);
          }
          // If Clinician
          else if (user_type == "CL") {
            // this.setupParentUi(user_id);
            this.controllerFor("application").set('isParticipant', true);
            this.controllerFor("dashboard").set('isParticipant', true);
            this.session.set('data.isParticipant', true);
            this.session.set('data.isParent', true);
            this.session.set('data.isClinician', true);
            this.session.set('data.canSendMessages', user.get('can_send_messages'));
            if (!this.session.get('data.viewingId')) {
              setTimeout(function () {
                $('.participant-application-view').addClass('clinician');
              }, 500);
            }
          } else {
            localStorage.setItem("participantAge", "");
            localStorage.setItem("selected_theme", 'high_contrast');
            this.controllerFor("application").set('isParticipant', false);
            this.controllerFor("dashboard").set('isParticipant', false);
            this.session.set('data.isParticipant', false);
            this.session.set('data.isParent', false);
            this.session.set('data.useProfilePicture', user.get('avatar'));
          }
        }
        this.controllerFor("dashboard").getNotifications();
        this.controllerFor("dashboard").set('showLoader', false);
      }
      var that = this;
      setTimeout(function () {
        that.session.set('data.loginHedgdoc', "");
      }, 5000);
    },
    settingAccessTokenHedgedoc: function settingAccessTokenHedgedoc() {
      var existing_token = localStorage.getItem("access_token_hedgedoc");
      var accessToken = this.get('session.data.authenticated.access_token');
      if (existing_token != accessToken) {
        localStorage.setItem("access_token_hedgedoc", accessToken);
      }
    },
    /************* Setup UI for Parent ******* */
    setupParentUi: function setupParentUi(user_id) {
      var that = this;
      this.store.query('parentparticipant', {
        parent: user_id
      }).then(function (parentparticipants) {
        if (parentparticipants.length > 0) {
          var parentparticipant = parentparticipants.get('firstObject');
          var get_participant_age = parentparticipant.get('get_participant_age');
          var get_participant_dark_theme = parentparticipant.get('get_participant_dark_theme');
          that.changeTheme(get_participant_age, get_participant_dark_theme);
          localStorage.setItem("participantAge", get_participant_age);
          localStorage.setItem("selected_theme", parentparticipant.get('dark_theme'));
        }
      });
    },
    //Get Participant's age
    getAge: function getAge(birthday) {
      // birthday is a date
      var dob = new Date(birthday);
      var month_diff = Date.now() - dob.getTime();
      var age_dt = new Date(month_diff);
      var year = age_dt.getUTCFullYear();
      var age = Math.abs(year - 1970);
      return age;
    },
    //Set Theme
    changeTheme: function changeTheme(participantAge, selected_theme) {
      if (!selected_theme) {
        if (participantAge <= 12) {
          //primary colors
          document.documentElement.style.setProperty('--primary-color-1', "#4281A4");
          document.documentElement.style.setProperty('--primary-color-2', "#48A9A6");
          document.documentElement.style.setProperty('--primary-color-3', "#D4B483");
          document.documentElement.style.setProperty('--primary-color-4', "#C1666B");
          //secondary colors
          document.documentElement.style.setProperty('--secondary-color-1', "#1A3A61");
          document.documentElement.style.setProperty('--secondary-color-2', "#E4DFDA");
          document.documentElement.style.setProperty('--secondary-color-3', "#80606E");
          //font colors
          document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
          document.documentElement.style.setProperty('--font-color-2', "#737373");
          document.documentElement.style.setProperty('--font-color-3', "#131929");
          document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

          //Menu color
          document.documentElement.style.setProperty('--font-inverted-white', "white");
          document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
          document.documentElement.style.setProperty('--menu-color-1', "#003E8F");
          document.documentElement.style.setProperty('--menu-color-2', "#28B4CC");
          document.documentElement.style.setProperty('--menu-color-3', "#EF5406");
          document.documentElement.style.setProperty('--menu-color-4', "#19C89C");
          document.documentElement.style.setProperty('--menu-color-5', "#822EB2");
          document.documentElement.style.setProperty('--menu-color-6', "#FACE03");
        } else if (participantAge > 12) {
          //primary colors
          document.documentElement.style.setProperty('--primary-color-1', "#28B4CC");
          document.documentElement.style.setProperty('--primary-color-2', "#19C89C");
          document.documentElement.style.setProperty('--primary-color-3', "#FACE03");
          document.documentElement.style.setProperty('--primary-color-4', "#822EB2");
          //secondary colors
          document.documentElement.style.setProperty('--secondary-color-1', "#EF5406");
          document.documentElement.style.setProperty('--secondary-color-2', "#003E8F");
          document.documentElement.style.setProperty('--secondary-color-3', "#FACE03");
          //font colors
          document.documentElement.style.setProperty('--font-color-1', "#1A3A61");
          document.documentElement.style.setProperty('--font-color-2', "#737373");
          document.documentElement.style.setProperty('--font-color-3', "#131929");
          document.documentElement.style.setProperty('--font-color-4', "#FFFFFF");

          //Menu color
          document.documentElement.style.setProperty('--font-inverted-white', "white");
          document.documentElement.style.setProperty('--font-color-5', "#1A3A61");
          document.documentElement.style.setProperty('--menu-color-1', "#4281A4");
          document.documentElement.style.setProperty('--menu-color-2', "#7C606B");
          document.documentElement.style.setProperty('--menu-color-3', "#C1666B");
          document.documentElement.style.setProperty('--menu-color-4', "#48A9A6");
          document.documentElement.style.setProperty('--menu-color-5', "#1A3A61");
          document.documentElement.style.setProperty('--menu-color-6', "#D4B483");
        }
      } else {
        // document.documentElement.style.setProperty('--primary-color-1', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--primary-color-2', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--primary-color-3', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--primary-color-4', "rgba(0, 0, 0, 0.801)");
        // //secondary colors
        // document.documentElement.style.setProperty('--secondary-color-1', "white");
        // document.documentElement.style.setProperty('--secondary-color-2', "white");
        // document.documentElement.style.setProperty('--secondary-color-3', "white");
        // //font colors
        // document.documentElement.style.setProperty('--font-color-1', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--font-color-2', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--font-color-3', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--font-color-4', "rgba(0, 0, 0, 0.801)");

        // //Menu color
        // document.documentElement.style.setProperty('--font-inverted-white', "white");
        // document.documentElement.style.setProperty('--font-color-5', "white");

        // document.documentElement.style.setProperty('--menu-color-1', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--menu-color-2', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--menu-color-3', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--menu-color-4', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--menu-color-5', "rgba(0, 0, 0, 0.801)");
        // document.documentElement.style.setProperty('--menu-color-6', "rgba(0, 0, 0, 0.801)");
      }
    },
    checkOnboardingStudy: function checkOnboardingStudy(userId) {
      var that = this;
      var accessToken = this.get('session.data.authenticated.access_token');

      //1. If is onboarding study
      $.ajax({
        url: _environment.default.APP.API_HOST + '/studyusers?user=' + userId + '&completed=false&is_onboarding=true',
        type: 'GET',
        headers: {
          "Authorization": "Bearer " + accessToken
        },
        success: function success(res) {
          var studyusers = res.studyuser;
          if (studyusers[0]) {
            var studyuser = studyusers[0];
            var studyuserId = studyuser.id;
            that.get('router').transitionTo('studyview', studyuserId, "na");
          } else {
            that.controllerFor("dashboard").set('showDashboard', true);
          }
        },
        error: function error(err, xH) {
          var str = err.responseText;
          str = str.split(':["').pop();
          str = str.split('"]')[0];
          console.log(str);
        }
      });
    }
  });
});