define("momentumhub/controllers/mood-boosters", ["exports", "@fullcalendar/timegrid", "@fullcalendar/interaction", "momentumhub/utils", "momentumhub/config/environment"], function (_exports, _timegrid, _interaction, _utils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    plugins: [_timegrid.default, _interaction.default],
    isShowingEventModal: false,
    eventInFocus: null,
    updateEvents: false,
    showExampleEvents: false,
    examples: null,
    events: Ember.computed('model.events', 'updateEvents', function () {
      var model = this.get('model');
      var eventsList = [];
      if (model) {
        model.events.map(function (model) {
          eventsList.push(model.generateFullCalendarFormat());
        });
        return eventsList;
      }
    }),
    initializeNewEventData: function initializeNewEventData() {
      this.set('eventInFocus', {
        id: null,
        title: null,
        day: 1,
        start: null,
        end: null,
        background_colour: '#2BB989'
      });
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.initializeNewEventData();
      var participantAge = localStorage.getItem('participantAge');
      if (participantAge <= 12) {
        this.set('isTeenager', false);
      } else {
        this.set('isTeenager', true);
      }
      setTimeout(function () {
        var headers = document.getElementsByClassName('fc-day-header');
        var _iterator = _createForOfIteratorHelper(headers),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            var text = item.textContent;
            text = text.split(' ')[0];
            item.textContent = text;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }, 2000);
      this.get('store').findAll('mood-booster-example', {}).then(function (response) {
        _this.set('examples', response);
      });
      //Prevent the modal to show as default
    },
    createDraggable: function createDraggable() {
      setTimeout(function () {
        if (document.getElementById('draggable-container')) {
          new _interaction.Draggable(document.getElementById('draggable-container'), {
            itemSelector: '.draggable'
          });
        }
      }, 2000);
    },
    generateEventPayload: function generateEventPayload(event) {
      var dateFormat = {
        'hour': '2-digit',
        'minute': '2-digit',
        'hourCycle': 'h23'
      };
      var eventInFocus = {
        'id': event.id,
        'title': event.title,
        'day': event.start.getDay().toString(),
        'start': event.start.toLocaleTimeString('en-US', dateFormat),
        'end': event.end.toLocaleTimeString('en-US', dateFormat),
        'background_colour': event.backgroundColor
      };
      return eventInFocus;
    },
    updateEventDetails: function updateEventDetails(event, eventInFocus) {
      var keys = ['title', 'day', 'start', 'end', 'background_colour'];
      keys.map(function (key) {
        var value = eventInFocus[key];
        if (key == 'day' && value == '0') value = '7';
        event[key] = value;
      });
      return event;
    },
    classNames: ['css-framework-fancy-class'],
    targetAttachment: "none",
    participantId: 0,
    header: {
      isHeader: true
    },
    footer: {
      isFooter: true
    },
    body: {
      isBody: true
    },
    form1: {
      isForm1: true
    },
    form2: {
      isForm2: true
    },
    toggleEventModal: function toggleEventModal() {
      this.set('isShowingEventModal', !this.get('isShowingEventModal'));
    },
    actions: {
      toggleEventModal: function toggleEventModal() {
        this.toggleEventModal();
      },
      createOrUpdateEvent: function createOrUpdateEvent() {
        var _this2 = this;
        var eventInFocus = this.get('eventInFocus');
        var keys = ['title', 'day', 'start', 'end'];
        var hasErrors = false;
        keys.map(function (key) {
          if (!eventInFocus[key]) {
            $('#id_' + key).addClass('error');
            hasErrors = true;
            setTimeout(function () {
              $('#id_' + key).removeClass('error');
            }, 2000);
          }
        });
        if (hasErrors) {
          return;
        }
        var payload = eventInFocus;
        if (eventInFocus.id) {
          this.store.findRecord('mood-booster-event', eventInFocus.id).then(function (event) {
            event = _this2.updateEventDetails(event, eventInFocus);
            event.save().then(function (response) {
              /* var existingIndex = this.get('events').findIndex(e => e.id == response.id);
              var events = this.get('model.events');
              events.objectAt(existingIndex).reload(); */
              _this2.set('updateEvents', !_this2.get('updateEvents'));
            });
          });
        } else {
          var event = this.store.createRecord('mood-booster-event', payload);
          event.save().then(function (response) {
            var event = response.generateFullCalendarFormat();
            _this2.get('events').pushObject(event);
          });
        }
        this.toggleEventModal();
        this.initializeNewEventData();
      },
      setNewEventDay: function setNewEventDay(value) {
        this.set('eventInFocus.day', value);
      },
      clicked: function clicked(_ref, calendar) {
        var event = _ref.event,
          jsEvent = _ref.jsEvent,
          view = _ref.view;
        var eventInFocus = this.generateEventPayload(event);
        this.set('eventInFocus', eventInFocus);
        this.toggleEventModal();
      },
      eventReceive: function eventReceive(info) {
        var _this3 = this;
        var eventInFocus = this.generateEventPayload(info.event);
        var event = this.store.createRecord('mood-booster-event', eventInFocus);
        event.save().then(function (response) {
          var event = response.generateFullCalendarFormat();
          _this3.get('events').pushObject(event);
        });
      },
      eventDrop: function eventDrop(info) {
        var _this4 = this;
        var eventInFocus = this.generateEventPayload(info.event);
        var event = this.store.peekRecord('mood-booster-event', eventInFocus.id);
        if (eventInFocus.id) {
          event = this.updateEventDetails(event, eventInFocus);
          event.save().then(function (response) {
            _this4.set('updateEvents', !_this4.get('updateEvents'));
          });
        }
      },
      deleteEvent: function deleteEvent() {
        var _this5 = this;
        var eventInFocus = this.get('eventInFocus');
        if (eventInFocus.id) {
          var event = this.store.peekRecord('mood-booster-event', eventInFocus.id);
          if (event) {
            event.destroyRecord().then(function () {
              _this5.toggleEventModal();
              _this5.set('updateEvents', !_this5.get('updateEvents'));
            });
          }
          ;
        }
      },
      setBackgroundColour: function setBackgroundColour(colour) {
        this.set('eventInFocus.background_colour', colour);
      },
      toggleExampleEvents: function toggleExampleEvents() {
        this.set('showExampleEvents', !this.get('showExampleEvents'));
        this.createDraggable();
      },
      showMoodBoosterTipsModule: function showMoodBoosterTipsModule() {
        var moduleId = _environment.default.APP.MOOD_BOOSTERS_TIPS_CHILD;
        if (this.get('isTeenager')) {
          moduleId = _environment.default.APP.MOOD_BOOSTERS_TIPS_TEEN;
        }
        this.transitionToRoute('modulePreview', moduleId);
      }
    }
  });
});